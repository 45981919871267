import React, { useLayoutEffect, useState } from 'react';
import { Layout, Typography } from 'antd';
import { Parallax } from 'react-scroll-parallax';
import classnames from "classnames";
const { Content } = Layout;
const { Title } = Typography;
const images = [0, 1, 2];

export default function ParallaxComp() {
    // const parallax = useParallax({speed:50});
    // const parallaxf = useParallax({speed:50});
    // const parallaxs = useParallax({speed:35});
    
    const [visibleImagesMap, setVisibleImagesMap] = useState(
        images.reduce((map, image) => {
          map[image] = false;
          return map;
        }, {})
    );
    useLayoutEffect(() => {
    const handleScroll = () => {
        const scrollTop = document.documentElement.scrollTop;
        const viewportHeight = window.innerHeight;
        console.log('height', viewportHeight);
        const newVisibleImagesMap = images.reduce((map, image) => {
            map[image] = scrollTop >= image * viewportHeight;
            return map;
        }, {});

        setVisibleImagesMap(newVisibleImagesMap);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const renderContent = () => (
        <>
            <Parallax className="h100" translateY={[150, 0]}>
                <Title level={2} className="sectionMTitle">Complementor</Title>
                <div className='description'>
                    <ul className='pl15'>
                        <li>Deliver contextualized financial products to customer, add a revenue stream</li>
                        <li>Automate & integrate your customer’s journeys</li>
                        <li>Reach to more customers through the partner network</li>
                    </ul>
                </div>   
            </Parallax>
            <Parallax className="h100" translateY={[70, 50]}>
                <Title level={2} className="sectionMTitle">Distributor</Title>   
                <div className='description'>
                    <ul className='pl15'>
                        <li>Provide 130+ products to your customer, across 80+ suppliers</li>
                        <li>Better decisioning, higher conversion, more profitability</li>
                        <li>Digitally manage your relationship with the customer</li>
                    </ul>
                </div>   
            </Parallax>
            <Parallax className="h100" translateY={[150, -10]}>
                <Title level={2} className="sectionMTitle">Supply</Title> 
                <div className='description'>
                    <ul className='pl15'>
                        <li>Entire user journey completed on the network</li>
                        <li>Access to alternate data sources,  accurate decisioning</li>
                        <li>Enriched customer qualification, higher conversion</li>
                        <li>Deliver contextualized products to customers</li>
                    </ul>
                </div>     
            </Parallax>
        </>
    );
    return(
        <>
        <Layout className='white parallax d-none-xs'>
            <Content className='d-flex'>
                <div className="Parallax_content">{renderContent()}</div>
                <div className='sticky_container'>
                    <div className='sticky'>      
                        <div className="frame">
                            {images.map((image) => (
                                <div
                                className={classnames("image", `image_${image}`, {
                                    image_visible: visibleImagesMap[image]
                                })}
                                key={image}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </Content>
        </Layout>
        </>
    )
}