import React, { useState } from 'react';
import Head from '../Header/Header';
import Foot from '../Footer/Footer';
import { Layout, Typography, Row, Col, Form, Button, Select, Input } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons';

const { Content } = Layout;
const { Title } = Typography;

function Help() {
  // state to storage the values given by the user when filling the input fields
  const [form] = Form.useForm();
  const [product, setProduct] = useState('');
  const [applicant, setApplicant] = useState('');
  const [profile, setProfile] = useState('');
  const [age, setAge] = useState(0);
  const [amount, setAmount] = useState(0);
  const [profit, setProfit] = useState(0);
  const [emi, setEMI] = useState('');
  const [tenure, setTenure] = useState('');
  const [interest, setInterest] = useState('');
  const [businessVintage, setBusinessVintage] = useState('');
  const [cop, setCop] = useState('');
  const [turnover, setTurnover] = useState('');
  const [foir, setFoir] = useState('');
  // state to storage error message
  const [error, setError] = useState('');

  // state to show hide fields
  const [show, setShow] = useState('');
  const [shownew, setShowNew] = useState('');

  // state to storage the results of the calculation
  const [results, setResults] = useState({
    calculatedLoanAmount: '',
    calculatedLoanProfit: '',
    calculatedPropertyLoanAmount: '',
    calculatedPropertyLoanProfit: '',
    calculatedBusinessLoanAmount: '',
    calculatedPersonalLoanAmount: '',
    calculateCreditCardLimit: '',
    isResult: false,
  });

  // Form OnFinish Function:
    const onFinish = (values) => {
     // console.log(values);
      calculateResults(values);
      form.resetFields();
    };

    const handleProductChange = (event) => {
      const product = event;
      setShow(product);
      setProduct(product);
    };

    const handleApplicantChange = (event) =>{
      const applicant = event;
      setShowNew(applicant);
      setApplicant(applicant);
    }
    const handleProfileChange = (event) =>{
      const profile = event;
      //console.log('profile', profile)
    }

  // Calculation
  const calculateResults = ({ amount, profit, interest, tenure, emi, cop, turnover, foir, profile, margin }) => {
    const userAmount = Number(amount);
    const userEmi = Number(emi);
    const userCop = Number(cop);

    if((amount > 24999 && amount < 50000) || (profit > 24999 && profit < 50000)){
      foir = 50;
    }
    else if((amount >= 50001 && amount < 75000) || (amount >= 50001 && amount < 75000)){
      foir = 60;
    }
    else if((amount >= 75000) || profit >= 75000){
      foir = 70;
    }
    else{
      foir = 0;
    }
    //console.log('foir', foir);

    const Numerator = ((userAmount* (foir/100)) - userEmi);
    const ProfitNumerator = (((profit/12)* (foir/100)) - userEmi);
    const t = Math.min((tenure), (60 - age), 30);
    //console.log('calculated tenure', t);
    const x = Math.pow(1 + (interest/100)/12, 12 * t);
    const xx = x-1;
    const y = (100000) * ((interest/100)/12) * x;
    const denominator = y/xx;
    //denomminator means PMT
    //console.log('Numerator', Numerator);
    //console.log(xx);
    //console.log(y);
    //console.log('denominator', denominator)
    //console.log('loan amount', Numerator/denominator);  
    
    //if home loan + amount
    const calculatedLoanAmount = Numerator/denominator;
    //console.log('Home Loan Amount', calculatedLoanAmount)
    const emiCalculate = Numerator;
    //console.log('Home Loan Emi', emiCalculate)

    //if home loan + profit
    const calculatedLoanProfit = ProfitNumerator/denominator;
    //console.log('Home Loan Profit Amount', calculatedLoanProfit)
    const emiCalculateProfit = ProfitNumerator;
    //console.log('Home Loan Profit Emi', emiCalculateProfit)

    //if property of cost then + amount
    const calculatedPropertyLoanAmount = Math.min((calculatedLoanAmount), userCop * 0.6);
    //console.log('LAP Loan Amount', calculatedPropertyLoanAmount);
    const emiCalculateProperty = denominator * calculatedPropertyLoanAmount;
    //console.log('LAP Loan Amount EMI', emiCalculateProperty);

    //if property of cost then + profit
    const calculatedPropertyLoanProfit = Math.min((ProfitNumerator/denominator), userCop * 0.6);
    //console.log('LAP Loan Amount Profit', calculatedPropertyLoanProfit);
    const emiCalculatePropertyProfit = denominator * calculatedPropertyLoanProfit;
    //console.log('LAP Loan Amount EMI', emiCalculatePropertyProfit);


    if((profile === 'Retailer') || profile === 'Wholesaler'){
      margin = 0.08;
    }
    else if(profile === 'Services'){
      margin = 0.3;
    }
    else if(profile === 'Manufacturing'){
      margin = 0.1;
    }
    else{
      margin = 0;
    }

    //console.log('margin', margin);

    //if Business Loan then
    const calculatedBusinessLoanAmount = ((((turnover/12)*margin*(70/100))-userEmi)/denominator);
    //console.log('business loan amount', calculatedBusinessLoanAmount);
    const emiBusinessLoan = denominator * calculatedBusinessLoanAmount;
    //console.log('business emi', emiBusinessLoan);

    //if Personal Loan then
    const calculatedPersonalLoanAmount = (((userAmount * (50/100)) - userEmi))/denominator;
    //console.log('Personal Loan', calculatedPersonalLoanAmount);
    const emiPersonalLoan = denominator * calculatedPersonalLoanAmount;
    //console.log('Personal EMI', emiPersonalLoan);

    //if Credit Card then 
    const calculateCreditCardLimit = Math.min(2 * ( userAmount - emi ), 300000)
    //console.log('Credit Card Limit', calculateCreditCardLimit);

    setResults({
      calculatedLoanAmount: calculatedLoanAmount.toFixed(2),
      emiCalculate: emiCalculate.toFixed(2),

      calculatedLoanProfit: calculatedLoanProfit.toFixed(2),
      emiCalculateProfit: emiCalculateProfit.toFixed(2),

      calculatedPropertyLoanAmount : calculatedPropertyLoanAmount.toFixed(2),
      emiCalculateProperty : emiCalculateProperty.toFixed(0),

      calculatedPropertyLoanProfit : calculatedPropertyLoanProfit.toFixed(2),
      emiCalculatePropertyProfit : emiCalculatePropertyProfit.toFixed(0),

      calculatedBusinessLoanAmount : calculatedBusinessLoanAmount.toFixed(2),
      emiBusinessLoan : emiBusinessLoan.toFixed(0),

      calculatedPersonalLoanAmount : calculatedPersonalLoanAmount.toFixed(2),
      emiPersonalLoan : emiPersonalLoan.toFixed(0),

      calculateCreditCardLimit : calculateCreditCardLimit,

      calculateTenure : t,
      isResult: true
    })
    return;
  };

  //Clear input fields
  const clearFields = () => {
    form.resetFields();
    setProduct('');
    setApplicant('');
    setAge('');
    setAmount('');
    setProfit('');
    setEMI('');
    setTenure('');
    setInterest('');
    setBusinessVintage('');
    setCop('');
    setTurnover('');
    setShow('');
    setShowNew('');
    setResults({
      calculatedLoanAmount: '',
      calculatedLoanProfit: '',
      calculatedPropertyLoanAmount: '',
      calculatedPropertyLoanProfit:'',
      calculatedBusinessLoanAmount: '',
      calculatedPersonalLoanAmount: '',
      calculateCreditCardLimit: '',
      isResult: false,
    });
  };
 
  return (
    <>
    
    <Layout className='white'>
        <Content>
          <Row justify='space-around' gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center">
            <Col xs={{span:24}} md={{span:12}} lg={{span: 13}}>
              <div className='calculator'>
                <div className='form'>
                  <Title level={2} className="sectionTitle text-center">Loan Calculator</Title>
                  {/* {error ? <p className='error ant-alert ant-alert-error ant-alert-no-icon' style={{marginBottom:'10px'}}>{error}</p> : ''} */}
                  {/* <Alert message={error} type="error"></Alert> */}
                  <Form labelCol={{span:24}} autoComplete="off" onFinish={onFinish} form={form}>
                    {/* ternary operator manages when the calculator and results will be displayed to the user */}
                    {!results.isResult ? (
                      //Form to collect data from the user
                      <div className='form-items calculator-form'>
                        <Form.Item name="product" label="Product" rules={[
                            {
                              required: true,
                              message:'Please select product'
                            },
                          ]}>
                          <Select placeholder='Select Product' style={{width:'100%'}} onChange={handleProductChange}>
                            <Select.Option value="Home-Loan">Home Loan</Select.Option>
                            <Select.Option value="Lap">LAP</Select.Option>
                            <Select.Option value="Business-Loan">Business Loan</Select.Option>
                            <Select.Option value="Personal-Loan">Personal Loan</Select.Option>
                            <Select.Option value="Credit-Card">Credit Card</Select.Option>
                          </Select>
                        </Form.Item>
                        {
                          show !== '' && show !== 'Business-Loan' && show !== 'Personal-Loan' && show !== 'Credit-Card' ? (
                          <Form.Item name="applicant" label="Applicant Type" rules={[
                            {
                              required: true,
                              message:'Please select applicant type'
                            },
                          ]}>
                            <Select placeholder="Select Applicant type" onChange={handleApplicantChange}>
                              <Select.Option value="Salaried">Salaried</Select.Option>
                              <Select.Option value="Self-Employed">Self-Employed</Select.Option>
                            </Select>
                          </Form.Item>
                        ) : '' }
                        {
                        (show === 'Lap') && (
                        <Form.Item name="cop" label="Market Value of Property (INR)" rules={[
                          {
                            required: true,
                            message:'Please enter market value of property'
                          },
                        ]}>
                          <Input
                            type='number'
                            min={0}
                            placeholder='Market Value of Property'
                            onChange={(e)=> setCop(e.target.value)}
                          />
                        </Form.Item>
                        ) }
                        {
                        (show === 'Business-Loan') && (
                        <Form.Item name="turnover" label="Gross Annual Turnover" rules={[
                          {
                            required: true,
                            message:'Please enter gross annual turnover'
                          },
                        ]}>
                          <Input
                            type='number'
                            min={0}
                            placeholder='Gross Annual Turnover'
                            onChange={(e)=> setTurnover(e.target.value)}
                          />
                        </Form.Item>
                        ) }
                        {
                          (show === 'Home-Loan' || show === 'Lap' || show === 'Personal-Loan' || show === 'Credit-Card') ? (
                          <Form.Item name="age" label="Age" rules={[
                            {
                              required: true,
                              message:'Please enter age'
                            },
                          ]}>
                            <Input
                              type='number'
                              min={0}
                              placeholder='Your Age'
                            onChange={(e) => setAge(e.target.value)}
                            />
                          </Form.Item>
                        ) : '' }
                        {
                        ((show === 'Home-Loan' || show === 'Lap') && shownew === 'Salaried') || (show === 'Personal-Loan') || (show === 'Credit-Card') ? (
                        <Form.Item name="amount" label="Net Salary (Monthly)" 
                          rules={[
                            {
                              required:true,
                              message:'Please enter net salary'
                            },
                            {
                              whitespace:true
                            },
                          ]}
                          >
                            <Input
                              type='number'
                              min={0}
                              placeholder='Net Salary'
                              onChange={(e)=> setAmount(e.target.value)}
                            />
                        </Form.Item>
                        ): ''}
                        {
                        ((show === 'Home-Loan' || show === 'Lap') && shownew === 'Self-Employed') ? (
                        <Form.Item name="profit" label="Net Profit For The Year" 
                          rules={[
                            {
                              required:true,
                              message:'Please enter net profit for the year'
                            },
                            {
                              whitespace:true
                            },
                          ]}
                          >
                            <Input
                              type='number'
                              min={0}
                              placeholder='Net Profit'
                              onChange={(e)=> setProfit(e.target.value)}
                            />
                        </Form.Item>
                        ): ''}
                        {
                        (shownew !== '' || show === 'Business-Loan' || show === 'Personal-Loan' || show === 'Credit-Card') && (
                        <Form.Item name="emi" label="Monthly Obligations (EMI)" rules={[
                            {
                              required: true,
                              message:'Please enter emi'
                            },
                          ]}>
                          <Input
                            type='number'
                            min={0}
                            placeholder='EMI'
                            onChange={(e)=> setEMI(e.target.value)}
                          />
                        </Form.Item>
                        )}
                        {
                        (shownew !== '' && (show === 'Home-Loan' || show === 'Lap')) || (show === 'Personal-Loan') || (show === 'Business-Loan') ? (
                        <Form.Item name="tenure" label="Tenure (Years)" rules={[
                            {
                              required: true,
                              message:'Please enter years'
                            },
                          ]}>
                          <Input
                            type='number'
                            min={0}
                            placeholder='Tenure'
                            onChange={(e)=> setTenure(e.target.value)}
                          />
                        </Form.Item>
                        ) : ''}
                        {
                        (shownew !== '' && (show === 'Home-Loan' || show === 'Lap')) || (show === 'Personal-Loan') || (show === 'Business-Loan') ? (
                        <Form.Item name="interest" label="Rate of Interest" rules={[
                            {
                              required: true,
                              message:'Please enter ROI'
                            },
                          ]}>
                          <Input
                            type='number'
                            placeholder='Interest'
                            onChange={(e)=> setInterest(e.target.value)}
                          />
                        </Form.Item>
                        ) : ''}
                        {
                          show === 'Business-Loan' ? (
                          <Form.Item name="profile" label="Select Your Profile" rules={[
                            {
                              required: true,
                              message:'Please select profile'
                            },
                          ]}>
                            <Select placeholder="Select Your Profile" onChange={handleProfileChange}>
                              <Select.Option value="Retailer">Retailer</Select.Option>
                              <Select.Option value="Wholesaler">Wholesaler</Select.Option>
                              <Select.Option value="Services">Services</Select.Option>
                              <Select.Option value="Manufacturing">Manufacturing</Select.Option>
                            </Select>
                          </Form.Item>
                        ) : '' }
                        {
                        (show === 'Home-Loan' && shownew === 'Self-Employed') || (show === 'Business-Loan') ? (
                        <Form.Item name="businessVintage" label="Business Vintage (Years)" rules={[
                          {
                            required: true,
                            message:'Please enter business vintage'
                          },
                        ]}>
                          <Input
                            type='number'
                            min={0}
                            placeholder='Business Vintage'
                            onChange={(e)=> setBusinessVintage(e.target.value)}
                          />
                        </Form.Item>
                        ): '' }
                        <Form.Item>
                          <Button className='button btn-orange' type='primary' htmlType='submit' style={{width:'100%'}}>Submit</Button>
                        </Form.Item>
                      </div>
                    ) : (
                      //   Form to display the results to the user
                      <div className='form-items'>
                        {/* <h4 style={{fontFamily:'poppins-medium'}}>
                          <div>Product: {product}</div>
                          <div>{ (applicant === '' || applicant === 'undefined') ? '' : `Applicant type: ${applicant}`} </div>
                          <div>{ age === 0 || age === '' ? '' : `Age: ${age} years`} </div>
                          <div>{ amount === 0 || amount === '' ? '' : `Income (INR): ${amount}`}</div>
                          <div>{ emi === '' ? '' : `Monthly obligations / EMI (INR): ${emi}`} </div>
                          <div>{ tenure === '' ? '' : `Tenure: ${tenure} years`} </div>
                          <div>{ interest === '' ? '' : `Expected interest rate: ${interest}%`} </div>
                          <div>{ businessVintage === '' || businessVintage === undefined ? '' :`Business Vintage: ${businessVintage} years` } </div>
                          <div>{ cop === '' || cop === undefined ? '' :`Cost of property (INR Lakhs): ${cop}`} </div>
                          <div>{ (turnover === '' || turnover === 'undefined') ? '' : `Turnover: ${turnover}`}</div>     
                        </h4> */}
                        {/* <div style={{marginBottom:'10px'}}>
                          <label id='label'>Eligibility:</label>
                          { (age >= 21 || age === 0 || age === '') && (amount >= 24999 || amount === 0 || amount === '') ?
                            <Input type='text' value='Eligible' disabled />
                          : <Input type='text' value='Not Eligible' disabled /> }
                        </div> */}

                        {/* Home Loan calculation visible */}
                        { (age >= 21 && age <= 59 || age === 0 || age === '') && (amount >= 25000 || amount === 0 || amount === '') && (product === 'Home-Loan') && (applicant === 'Salaried') && (results.calculatedLoanAmount > 0) ?
                        <>
                        <div className='LoanResultsPnl flex-xs-column'>
                          <div className='loanProductsPNL success-loan'>
                            <CheckCircleOutlined />
                            <div>You are eligible!</div>
                          </div> 
                          <div className='loanProductsPNL'>
                            <div className='loanResult'>{results.calculatedLoanAmount} Lac</div>
                            <div>Loan Amount </div>
                          </div>
                        </div>
                        <div className='LoanResultsPnl flex-xs-column'>
                          <div className='loanProductsPNL'>
                            <div className='loanResult'>{results.emiCalculate}</div>
                            <div>EMI</div>
                          </div>
                          <div className='loanProductsPNL'>
                            <div className='loanResult'>{results.calculateTenure} Years</div>
                            <div>Loan Tenure</div>
                          </div> 
                        </div> </> : 
                        (age >= 21 && age <= 59 || age === 0 || age === '') && (profit >= 25000 || profit === 0 || profit === '') && (product === 'Home-Loan') && (applicant === 'Self-Employed') && (results.calculatedLoanProfit > 0) ?
                        <>
                        <div className='LoanResultsPnl flex-xs-column'>
                          <div className='loanProductsPNL success-loan'>
                            <CheckCircleOutlined />
                            <div>You are eligible!</div>
                          </div> 
                          <div className='loanProductsPNL'>
                            <div className='loanResult'>{results.calculatedLoanProfit} Lac</div>
                            <div>Loan Amount </div>
                          </div>
                        </div>
                        <div className='LoanResultsPnl flex-xs-column'>
                          <div className='loanProductsPNL'>
                            <div className='loanResult'>{results.emiCalculateProfit}</div>
                            <div>EMI</div>
                          </div>
                          <div className='loanProductsPNL'>
                            <div className='loanResult'>{results.calculateTenure} Years</div>
                            <div>Loan Tenure</div>
                          </div> 
                        </div></> : 
                        (age >= 21 || age === 0 || age === '') && (amount >= 24999 || amount === 0 || amount === '') && (product === 'Lap') && (applicant === 'Salaried') && (results.calculatedPropertyLoanAmount > 0) ?
                        <>
                        <div className='LoanResultsPnl flex-xs-column'>
                          <div className='loanProductsPNL success-loan'>
                            <CheckCircleOutlined />
                            <div>You are eligible!</div>
                          </div>
                          <div className='loanProductsPNL'>
                            <div className='loanResult'>{results.calculatedPropertyLoanAmount} Lac</div>
                            <div>Loan Amount</div>
                          </div>
                        </div>
                        <div className='LoanResultsPnl flex-xs-column'>
                          <div className='loanProductsPNL'>
                            <div className='loanResult'>{results.emiCalculateProperty}</div>
                            <div>EMI</div>
                          </div>
                          <div className='loanProductsPNL'>
                            <div className='loanResult'>{results.calculateTenure} Years</div>
                            <div>Loan Tenure</div>
                          </div> 
                        </div></> : 
                        (age >= 21 && age <= 59 || age === 0 || age === '') && (profit >= 25000 || profit === 0 || profit === '') && (product === 'Lap') && (applicant === 'Self-Employed') && (results.calculatedPropertyLoanProfit > 0) ?
                        <>
                        <div className='LoanResultsPnl flex-xs-column'>
                          <div className='loanProductsPNL success-loan'>
                            <CheckCircleOutlined />
                            <div>You are eligible!</div>
                          </div> 
                          <div className='loanProductsPNL'>
                            <div className='loanResult'>{results.calculatedPropertyLoanProfit} Lac</div>
                            <div>Loan Amount </div>
                          </div>
                        </div>
                        <div className='LoanResultsPnl flex-xs-column'>
                          <div className='loanProductsPNL'>
                            <div className='loanResult'>{results.emiCalculatePropertyProfit}</div>
                            <div>EMI</div>
                          </div>
                          <div className='loanProductsPNL'>
                            <div className='loanResult'>{results.calculateTenure} Years</div>
                            <div>Loan Tenure</div>
                          </div> 
                        </div></> : 
                        (businessVintage >= 1) && (amount >= 24999 || amount === 0 || amount === '') && (product === 'Business-Loan') && (results.calculatedBusinessLoanAmount > 0) ?
                        <div className='LoanResultsPnl flex-xs-column'>
                          <div className='loanProductsPNL success-loan'>
                            <CheckCircleOutlined />
                            <div>You are eligible!</div>
                          </div>
                          <div className='loanProductsPNL'>
                            <div className='loanResult'>{results.calculatedBusinessLoanAmount} Lac</div>
                            <div>Loan Amount</div>
                          </div>
                          <div className='loanProductsPNL'>
                            <div className='loanResult'>{results.emiBusinessLoan}</div>
                            <div>EMI</div>
                          </div>
                        </div> : 
                        (age >= 21 || age === 0 || age === '') && (amount >= 24999 || amount === 0 || amount === '') && (product === 'Personal-Loan') && (results.calculatedPersonalLoanAmount > 0) ?
                        <div className='LoanResultsPnl flex-xs-column'>
                          <div className='loanProductsPNL success-loan'>
                            <CheckCircleOutlined />
                            <div>You are eligible!</div>
                          </div>
                          <div className='loanProductsPNL'>
                            <div className='loanResult'>{results.calculatedPersonalLoanAmount} Lac</div>
                            <div>Loan Amount</div>
                          </div>
                          <div className='loanProductsPNL'>
                            <div className='loanResult'>{results.emiPersonalLoan}</div>
                            <div>EMI</div>
                          </div> 
                        </div> : 
                        (age >= 18 || age === 0 || age === '') && (amount >= 24999 || amount === 0 || amount === '') && (product === 'Credit-Card') && (results.calculateCreditCardLimit > 0) ?
                        <div className='LoanResultsPnl flex-xs-column'>
                          <div className='loanProductsPNL success-loan'>
                            <CheckCircleOutlined />
                            <div>You are eligible!</div>
                          </div>
                          <div className='loanProductsPNL'>
                            <div className='loanResult'>{results.calculateCreditCardLimit}</div>
                            <div>Limit</div>
                          </div>
                        </div> : 
                        <div className='LoanResultsPnl flex-xs-column'>
                          <div className='loanProductsPNL failure-loan d-flex align-items-center justify-content-center'>
                            <CloseCircleOutlined />
                            <div>You seem to be ineligible, please contact your relationship manager.</div>
                          </div>
                        </div>
                        }
                        {/* Button to clear fields */}
                        <Input
                          className='button btn-orange mt-xs-10'
                          value='Calculate again'
                          type='button'
                          onClick={clearFields}
                        />
                      </div>
                    )}
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Content>
    </Layout>
    
    </>
  );
}

export default Help;