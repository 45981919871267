import React, {useState, useRef } from 'react';
import { Layout, Typography, Row, Col, Button, Form, Input, Select, Spin, notification } from 'antd';
import Head from '../Header/Header'
import Foot from '../Footer/Footer'
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";

const { Content } = Layout;
const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;


const Contact = () => {
    //const form = useRef();
    const [form] = Form.useForm();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const captchaRef = useRef(null)
    const NOTIFICATION_DETAILS = {
        success: {
          message: "Details Submitted!",
          description: "We've got your information. Our team will get in touch you shortly!",
        },
        error: {
          message: "Something went wrong!",
          description: "Please try again later",
        },
        captchaError: {
            message: "Error!",
            description: "Please Verify Captcha",
        },
    };
    const showNotification = (type, details) => {
        notification[type]({
          message: details.message,
          description: details.description,
        });
      };

    const onFinish = (values) => {
        //console.log("Captcha value:", values);
        const token = captchaRef.current.getValue();
        captchaRef.current.reset();
        console.log('token', token);
        if (token === ''){
            showNotification("error", NOTIFICATION_DETAILS.captchaError);
        }
        else if (values.error) {
            showNotification("error", NOTIFICATION_DETAILS.error); // Show Error Notification
        } 
        else {
            showNotification("success", NOTIFICATION_DETAILS.success); // Show Success Notification
            form.resetFields();
        console.log(values);
        setData(values);
        setLoading(true);
        setTimeout(() =>{
           form.resetFields();
            setLoading(false);
        }, 500)
        emailjs.sendForm('service_smu0swp', 'template_7q582y8', '#myForm' , 'bgxH8usZYCOYxA4DA')
            .then((result) => {
                console.log('email sent successfully');
            }, (error) => {
                console.log('email not sent');
            });
        }
    };

    function onCaptchaChange(values) {
        const token = captchaRef.current.getValue();
        console.log("Captcha value:", token);

    }
    
    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select style={{ width: 70 }}>
            <Option value="91">+91</Option>
          </Select>
        </Form.Item>
    );

		return(
          <>
          <Head/>
          <Layout className='white'>
            <Content className='pagePadding'>
                <div className="site-layout-content">
                    <Row justify="space-between" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center">
                        <Col xs={{span:0}} md={{span:12}} lg={{span: 13}}>
                            <img src={require('../../img/bottom-img.png')} style={{width:'85%'}} alt="contact"/>
                        </Col>
                        <Col xs={{span:24}} md={{span:12}} lg={{span: 11}}>
                            <div className='contactRightPnl'>
                            <Title level={2} className="sectionTitle">Contact Us</Title>   
                            {/* <p className='contactDesc'>OneInfinity brings technology innovation to the traditional field of business cashflow management. 
                            It delivers just-in-time working capital options at affordable costs to all market participants.</p> */}
                            <Spin spinning = {loading}>
                            <Form id='myForm' labelCol={{span:24}} form={form} onFinish={onFinish} initialValues={{ prefix: "91" }} autoComplete="off" className='form'>
                                {/* <div className='d-flex justify-space-between'>
                                <div className='w-49'> */}
                                <Form.Item 
                                    name={'fname'}
                                    rules={[
                                    {
                                        required: true,
                                        message: "please enter your full name",
                                    },
                                    ]}
                                >
                                    <Input
                                    name= 'fname'
                                    type="text"
                                    placeholder="First Name"
                                    // onChange={handleChange}
                                    />
                                </Form.Item>
                                {/* </div> */}
                                {/* <div className='w-49'>
                                <Form.Item
                                    name="lname"
                                    rules={[
                                    {
                                        required: true,
                                        message: "please enter last name",
                                    },
                                    ]}
                                >
                                    <Input
                                    name='lname'
                                    type="text"
                                    placeholder="Last Name"
                                    />
                                </Form.Item>
                               </div></div> */}
                                <Form.Item
                                    name="email"
                                    rules={[
                                    {
                                        type: "email",
                                        message: "Please enter valid email",
                                    },
                                    {
                                        required: true,
                                        message: "Please enter your email",
                                    },
                                    ]}
                                >
                                    <Input
                                    name='email'
                                    placeholder="Email"
                                    // onChange={handleChange}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="mobile"
                                    rules={[
                                    {
                                        required: true,
                                        message: "please enter mobile number",
                                    },
                                    {
                                        min:10,
                                        max:10,
                                        message: "please enter 10 digit mobile number",
                                    },
                                    ]}
                                >
                                    <Input
                                    name='mobile'
                                    addonBefore={prefixSelector}
                                    type="Number"
                                    placeholder="Mobile Number"
                                    // onChange={handleChange}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="company"
                                    rules={[
                                    {
                                        required: true,
                                        message: "please enter company name",
                                    },
                                    ]}
                                >
                                    <Input
                                    name='company'
                                    type="text"
                                    placeholder="Company Name"
                                    // onChange={handleChange}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="textarea"
                                    rules={[
                                    {
                                        required: true,
                                        message: "Send your query to us",
                                    },
                                    ]}
                                >
                                    <TextArea name='textarea' rows={4} placeholder="Send your query to us" />
                                </Form.Item>
                                <Form.Item>
                                    <ReCAPTCHA sitekey="6Lc7rKgkAAAAAHtfbqcai8Siv0jO4v4-KYDnwhIa" onChange={onCaptchaChange} ref={captchaRef} />
                                </Form.Item>
                                <Form.Item>
                                    <Button className="button btn-orange" type="primary" htmlType="submit">Submit</Button>
                                </Form.Item>
                                </Form>
                                </Spin>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Content>
          </Layout>            
          <Foot />
          </>    
        );
}
export default Contact;
