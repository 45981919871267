import React from 'react'
import Head from '../Header/Header'
import Foot from '../Footer/Footer'

export default function Demand() {
  return (
    <>
      <Head></Head>
      <h1>this is demand page</h1>
      <Foot />
    </>
  )
}
