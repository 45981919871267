import React from 'react';
import Head from '../Header/Header';
import Foot from '../Footer/Footer';
import { Layout, Typography, Row, Col } from 'antd';
import { Link } from "react-router-dom";
import {ReactComponent as Lead} from '../../img/icons/lead.svg';
import {ReactComponent as Channel} from '../../img/icons/channel.svg';
import {ReactComponent as Operational} from '../../img/icons/operationaleffi.svg';
import {ReactComponent as Lending} from '../../img/icons/lending.svg';
import {ReactComponent as Partners} from '../../img/icons/partners.svg';
import {ReactComponent as NewProduct} from '../../img/icons/newproductmanage.svg';
import {ReactComponent as Embedded} from '../../img/icons/embedded-solutions.svg';
import {ReactComponent as Access} from '../../img/icons/access-gateway.svg';
const { Content } = Layout;
const { Title } = Typography;


export default function OneBit() {
  return (
    <>
      <Head />
      <Layout className='white'>
        <Content>
          <div className="site-layout-content">
            <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center">
              <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                <Title level={2} className="sectionTitle text-left">OneBit</Title>
                <div className='Titledescription'>Create forms, journeys & products</div>
                <p className='mb-20 smWidth'>Customizable CRM for financial distribution with APIs to 100s of FIs, partner platforms & services</p>
                <Link to="/contact" className='btn-orange d-none-xs'>Contact Us</Link>
              </Col>
              <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                <img src={require('../../img/1bit.png')} alt="1Bit" style={{width:'100%'}} />
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
      <Layout className='white'>
        <Content>
            <div className="site-layout-content">
              <Title level={2} className="sectionTitle text-center">Values for you</Title>
                <div className='d-flex flex-xs-column ant-row'>
                  <div className='productsPNL'>
                    <img src={require('../../img/forms.png')} alt='forms'/>
                    <Title level={4} className="sectionTitle text-left">Application Forms on the go</Title>
                    <p>Create & modify application  forms for products by just defining fields and they are live to go on all interfaces instantly</p>
                  </div>
                
                  <div className='productsPNL'>
                    <img src={require('../../img/inbuild-int.png')} alt='inbuild-int'/>
                    <Title level={4} className="sectionTitle text-left">Team Created Journeys</Title>
                    <p>Draw out flow of user journeys & try them. Design as per context, customer need, team requirement & more as you need</p>
                  </div>
                
                  <div className='productsPNL'>
                    <img src={require('../../img/products.png')} alt='products'/>
                    <Title level={4} className="sectionTitle text-left">Launch Products in Weeks</Title>
                    <p>Product launch speed is 'the differentiator' across financial segments. Design, launch & test product on OI data before launch</p>
                  </div>

                  <div className='productsPNL'>
                    <img src={require('../../img/analyze.png')} alt='analyze'/>
                    <Title level={4} className="sectionTitle text-left">Analyze</Title>
                    <p>Analysis of ecosystem data to understand trends, portfolio, customer requirements & tinkering for re-launch.</p>
                  </div>
                </div>
            </div>
        </Content>
      </Layout>
      <Layout className='grey'>
        <Content>
          <div className="site-layout-content">
            <Title level={2} className="sectionTitle text-center">Benefits for you</Title>
            <div className='d-flex flex-xs-column ant-row'>
              <div className='productsPNL'>
                <img src={require('../../img/fastest-management.png')} alt='speed'/>
                <Title level={4} className="sectionTitle text-left">Speed</Title>
                <p>Design, test, try, retest and launch forms, journeys & products in days & not months for market leadership</p>
              </div>
              <div className='productsPNL'>
                <img src={require('../../img/testing.png')} alt='testing'/>
                <Title level={4} className="sectionTitle text-left">Testing</Title>
                <p>Test any product on OI ecosystem to simulate future outcomes for best results to put best foot forward</p>
              </div>
              <div className='productsPNL'>  
                <img src={require('../../img/scaleUp.png')} alt='scaleUp'/>
                <Title level={4} className="sectionTitle text-left">Customization</Title>
                <p>Customization at scale whether for product, team, partner or even at customer level with speed & flexibility</p>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
      <Layout className='white'>
        <Content>
            <div className="site-layout-content">
              <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center">
                <Col xs={{span:24}} md={{span:24}} lg={{span: 24}}>
                    <Title level={2} className="sectionTitle text-center">Set the network to work for you</Title>
                </Col>
                <Col xs={{span:24}} md={{span:24}} lg={{span: 24}}>
                  <Link className='extLink' to="/connect/1"><Lending /> Ecosystem Creation</Link>
                  <Link className='extLink' to="/integrate/1"><Embedded /> Embedded Solutions</Link>
                  <Link className='extLink' to="/integrate/2"><Access /> Workflow Solutions</Link>
                </Col>
              </Row>
            </div>
        </Content>
      </Layout>
    <Foot />
    </>
  )
}
