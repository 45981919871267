import React, {useEffect, useRef, useState} from 'react'
import Head from '../Header/Header'
import Foot from '../Footer/Footer'
import { Layout, Typography, Menu, Row, Col } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
import {ReactComponent as Lending} from '../../img/icons/lending.svg';
import {ReactComponent as Partners} from '../../img/icons/partners.svg';
import {ReactComponent as NewProduct} from '../../img/icons/newproductmanage.svg';
import {ReactComponent as WhatsApp} from '../../img/icons/whatsapp.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const { Content } = Layout;
const { Title } = Typography;

const Connect = () => {
  return (
    <div className="App">
      <Carousel />
    </div>
  );
}

const tabs = [
  {
    tag:"01",
    icon: <WhatsApp />,
    title: "Whatsapp/E-mail Connector",
    Content: "All businesses are run on communication and whether it be teams or network, WhatsApp & Email are the primary modes of communication. However, these conversations result in scattered data and documents and volumes too large to handle. OI connector brings all of these together with the CRM. And now you can also design campaigns and communications to customers"
  },
  {
    tag:"02",
    icon: <Lending />,
    title: "Ecosystem Creation",
    Content: "All businesses are run on communication and whether it be teams or network, WhatsApp & Email are the primary modes of communication. However, these conversations result in scattered data and documents and volumes too large to handle. OI connector brings all of these together with the CRM. And now you can also design campaigns and communications to customers"
  },
  {
    tag:"03",
    icon: <NewProduct />,
    title: "Offer/Product Updates",
    Content: "Thousands of financial products and services exist across thousands of financial Institutions. Then there are ever-ongoing changes to rules and policies. Keep yourself, your network and team up to date with these changes. Learn more about happenings in the industry and be a part of professional training by Financial Institutes"
  },
];

const postImages = [
  require("../../img/channel-partner.png"),
  require("../../img/channel-partner.png"),
  require("../../img/channel-partner.png"),
];

function Carousel() {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [selectedKeys, setSelectedKeys] = useState (['']);
  const navigate = useNavigate();
  const location = useLocation()
  const ref = useRef();
  useEffect(() => {
    //restartTimer();
    const index = parseInt(location.pathname.replace('/connect/', ""))
    if (currentIndex === -1) {
      setTimeout(() => {
        setCurrentIndex(index);
      }, 10);
    }else{
      setCurrentIndex(index);
    }

    return () => {
      setCurrentIndex(-1);
      clearInterval(ref.current);
    };
  }, [location.pathname]);

  // const restartTimer = () => {
  //   clearInterval(ref.current);
  //   ref.current = setInterval(() => {
  //     setCurrentIndex((pre) => {
  //       return (pre + 1) % tabs.length;
  //     });
  //   }, 5000);
  // };

  const onMenuItemClick = (e) => {
    navigate(e.key);
    setSelectedKeys([e.key]);
  }

  return (
    <>
    <Head />
    <Layout className='white' style={{minHeight:'100vh'}}>
      <Content style={{padding:'0px'}}>
        <div className="site-layout-content tab_content">
        <Row justify="space-between" gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
          <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
          
            <div className="tab_wrap">
            <Menu onClick={onMenuItemClick} selectedKeys={selectedKeys} className="productsMenu solutionsMenu"
              items={[
                {
                  label:'Manage',
                  key:'/manage/0',   
                },
                {
                  label:'Integrate',
                  key:'/integrate/0', 
                },
                {
                  label:'Connect',
                  key:'/connect/0', 
                  className:'active',   
                },
                {
                  label:'Decide',
                  key:'/decide/0',   
                },
              ]} />  
              <Title level={2}>Connect</Title>
              <p className='d-none'>Networks are a thing of beauty and their effects transform businesses. Deliver your product to millions of customers. ​​</p>
              {tabs.map((tab, index) => {
                return (
                  <div
                    key={index}
                    className={`tab ${currentIndex === index ? "active" : ""}`}
                    onClick={() => {
                      setCurrentIndex(index);
                     // restartTimer();
                    }}
                  >
                    <div className='d-flex'>
                      <span className="Tabtag">{tab.icon}</span>
                    <div>
                      <h2 className="post_title">{tab.title}</h2>
                      <span className='post_content'>{tab.Content}</span>
                    </div>
                    </div>
                  </div>
                );
              })}
            </div>
            </Col>
            <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
            <div className="content_wrap">
              <article className="box">
                <div className="content_image">
                  {/* <img src={postImages[currentIndex]} style={{ width: "100%" }} alt="reach" /> */}
                  <LazyLoadImage src={require('../../img/app-screen.png')}  effect="blur" style={{ width: "100%", marginTop:'40px' }} alt="connect"/>
                </div>
              </article>
            </div>
            </Col>
            </Row>
        </div>
      </Content>
    </Layout>
    <Foot />
    </>
  );
}


export default Connect;
