import React, {useEffect, useRef, useState} from 'react'
import Head from '../Header/Header'
import Foot from '../Footer/Footer'
import { Layout, Typography, Menu, Row, Col } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
import {ReactComponent as Recommendation} from '../../img/icons/recommendation-engine.svg';
import {ReactComponent as Rule} from '../../img/icons/rule-engine.svg';
import {ReactComponent as VAS} from '../../img/icons/vas.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const { Content } = Layout;
const { Title } = Typography;

const Decide = () => {
  return (
    <div className="App">
      <Carousel />
    </div>
  );
}

const tabs = [
  {
    tag:"01",
    icon: <Recommendation />,
    title: "Bank Statement Analysis",
    Content: "Bank statements are a mine of insights. Current solutions are either time consuming (think Excel) or costly. Now a solution that is bank grade and at the lowest Price. Compute ABB, Credit & debits, EMI/ cheque bounces, turnover and more in minutes Then combine it with The Power of the OI BRE and increase conversions 4X"
  },
  {
    tag:"02",
    icon: <Rule />,
    title: "Business Rule Engines",
    Content: "BREs are the secret to better conversions, suitable offers and customer satisfaction. Now, not only OI technology but your industry experience can be combined forming state of the art BREs. Configure rules for products and test on Ecosystem data to finetune. Those conversions will be out of the roof"
  },
  {
    tag:"03",
    icon: <VAS />,
    title: "Bureau Services",
    Content: "Bureau Reports, score and data is ‘the one’ powerful indicator. These are also the basis of pre-approved offers. OI  provides access to Bureau data (after due Customer consent) related to credit history which remains the strongest predictor of future behavior. Combine this with OI BRE and ensure STP journeys and instant offers"
  },
];

const postImages = [
  require("../../img/decisioning-engine.png"),
  require("../../img/decisioning-engine.png"),
  require("../../img/decisioning-engine.png"),
];

function Carousel() {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [selectedKeys, setSelectedKeys] = useState (['']);
  const navigate = useNavigate();
  const location = useLocation()
  const ref = useRef();
  useEffect(() => {
    //restartTimer();
    const index = parseInt(location.pathname.replace('/decide/', ""))
    if (currentIndex === -1) {
      setTimeout(() => {
        setCurrentIndex(index);
      }, 10);
    }else{
      setCurrentIndex(index);
    }

    return () => {
      setCurrentIndex(-1);
      clearInterval(ref.current);
    };
  }, [location.pathname]);

  // const restartTimer = () => {
  //   clearInterval(ref.current);
  //   ref.current = setInterval(() => {
  //     setCurrentIndex((pre) => {
  //       return (pre + 1) % tabs.length;
  //     });
  //   }, 5000);
  // };

  const onMenuItemClick = (e) => {
    navigate(e.key);
    setSelectedKeys([e.key]);
  }

  return (
    <>
    <Head />
    <Layout className='white' style={{minHeight:'100vh'}}>
      <Content style={{padding:'0px'}}>
        <div className="site-layout-content tab_content">
        <Row justify="space-between" gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
          <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
            <div className="tab_wrap">
            <Menu onClick={onMenuItemClick} selectedKeys={selectedKeys} className="productsMenu solutionsMenu"
              items={[
                {
                  label:'Manage',
                  key:'/manage/0',   
                },
                {
                  label:'Integrate',
                  key:'/integrate/0',  
                },
                {
                  label:'Connect',
                  key:'/connect/0',   
                }, 
                {
                  label:'Decide',
                  key:'/decide/0',  
                  className:'active',   
                }, 
              ]} />  
              <Title level={2}>Decide</Title>
              <p className='d-none'>Decisions are driven by data & its analysis, in real time with the right context. And these decision making needs to be customized.​</p>
              {tabs.map((tab, index) => {
                return (
                  <div
                    key={index}
                    className={`tab ${currentIndex === index ? "active" : ""}`}
                    onClick={() => {
                      setCurrentIndex(index);
                      //restartTimer();
                    }}
                  >
                    <div className='d-flex'>
                      <span className="Tabtag">{tab.icon}</span>
                    <div>
                      <h2 className="post_title">{tab.title}</h2>
                      <span className='post_content'>{tab.Content}</span>
                    </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Col>
          <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
            <div className="content_wrap">
              <article className="box">
                <div className="content_image">
                  {/* <img src={postImages[currentIndex]} style={{ width: "100%" }} alt="decide" /> */}
                  <LazyLoadImage src={require('../../img/1bit.png')}  effect="blur" style={{ width: "100%", marginTop:'40px' }} alt="1flo"/>
                </div>
              </article>
            </div>
          </Col>
        </Row>
        </div>
      </Content>
    </Layout>
    <Foot />
    </>
  );
}


export default Decide;
