import React, {useState} from 'react';
import logo from '../../img/logo.png';
import {ReactComponent as Automation} from '../../img/icons/automation.svg';
import {ReactComponent as Embedded} from '../../img/icons/embedded-solutions.svg';
import {ReactComponent as Access} from '../../img/icons/access-gateway.svg';
import {ReactComponent as Recommendation} from '../../img/icons/recommendation-engine.svg';
import {ReactComponent as Rule} from '../../img/icons/rule-engine.svg';
import {ReactComponent as VAS} from '../../img/icons/vas.svg';
import {ReactComponent as Lending} from '../../img/icons/lending.svg';
import {ReactComponent as Partners} from '../../img/icons/partners.svg';
import {ReactComponent as Lead} from '../../img/icons/lead.svg';
import {ReactComponent as Channel} from '../../img/icons/channel.svg';
import {ReactComponent as Operational} from '../../img/icons/operationaleffi.svg';
import {ReactComponent as NewProduct} from '../../img/icons/newproductmanage.svg';
import {ReactComponent as WhatsApp} from '../../img/icons/whatsapp.svg';
import { Menu, Layout, Col, Row, Drawer, Typography } from 'antd';  
import { Link, useNavigate } from "react-router-dom";
import './Header.css';

const { Header } = Layout;
const { Title } = Typography;
    
const MobItems = [   
    {
        label: 'Partners',
        key: 'partners',
        children: [
            {
                label:'Banks & NBFCs',
                key:'/banks',
            },
            {
                label:'Insurance',
                key:'/insurance',
            },
            {
                label:'Channel Partner',
                key:'/channel_partners',
            },
            {
                label:'Platform Partner',
                key:'/partner_platform',
            }
        ]
    },
    {
        label: 'Products',
        key: 'products',
        children: [
            {
                label:'Oneflo',
                key:'/oneflo',
            },
            {
                label:'OneWay',
                key:'/oneway',
            },
            {
                label:'OneBit',
                key:'/onebit',
            }
        ]
    },
    {
        label: 'Solutions',
        key: 'solutions',
        children: [
            {   
                label: 'Manage',
                key: '/manage/0'
            },
            {
                label:'Integrate',
                key:'/integrate/0'
            },
            {   
                label: 'Connect',
                key: '/connect/0'
            },
            {   
                label: 'Decide',
                key: '/decide/0'
            },
            
        ]
    }, 
    {
        label: 'About',
           key: '/about',
    },
    // {
    //     label: 'Distributor',
    //     key: '/distributor',
    // },
    // {
    //     label: 'Complementor',
    //     key: '/complementor',
    // },
    // {
    //     label: 'Supply',
    //     key:'/supply'
    // },
];

const items = [
    {
        label: 'Partners',
        key: 'partners',
        children: [
            {
                label:<MegaMenuPartners/>,
                key:'/MegaMenuPartners',
                style:{
                    height:'fit-content',
                    padding:0,
                    margin:0
                }
            }
        ]
    },   
    {
        label: 'Products',
        key: 'products',
        children: [
            {
                label:<MegaMenuProducts/>,
                key:'/MegaMenuProducts',
                style:{
                    height:'fit-content',
                    padding:0,
                    margin:0
                }
            }
        ]
    },
    {
        label: 'Solutions',
        key: 'solutions',
        children: [
            {
                label:<MegaMenu/>,
                key:'/MegaMenu',
                style:{
                    height:'fit-content',
                    padding:0,
                    margin:0,
                }
            }
        ]
    }, 
    {
        label: 'About',
           key: '/about',
    },
    // {
    //     label: 'Develops',
    //     key: '/develops',
    // },
    // {
    //     label: 'Distributor',
    //     key: '/distributor',
    // },
    // {
    //     label: 'Complementor',
    //     key: '/complementor',
    // },
    // {
    //     label: 'Supply',
    //     key:'/supply'
    // },
];
function MegaMenuPartners(){
    const [selectedKeys, setSelectedKeys] = useState (['']);
    const navigate = useNavigate();

    const onMenuItemClick = (e) => {
        navigate(e.key);
        setSelectedKeys([e.key]);
    }
    return(
        <div className='d-flex flex-column MegaMenu'>
            <div className='d-flex justify-space-between'>
                <div className='d-flex flex-column pad20 w-25'>
                    <Title level={4} className="MenuTitle">Banks & NBFCs</Title>
                    <Menu onClick={onMenuItemClick} selectedKeys={selectedKeys} className="productsMenu"
                    items={[
                        {
                            label:<Banks/>,
                            key:'/banks',   
                        },
                    ]} /> 
                </div>
                
                <div className='d-flex flex-column pad20 w-25'>
                    <Title level={4} className="MenuTitle">Insurance</Title>
                    <Menu onClick={onMenuItemClick} selectedKeys={selectedKeys} className="productsMenu"
                    items={[
                        {
                            label:<Insurance/>,
                            key:'/insurance',   
                        },
                    ]} />                 
                </div>

                <div className='d-flex flex-column pad20 w-25'>
                    <Title level={4} className="MenuTitle">Channel Partner</Title>
                    <Menu onClick={onMenuItemClick} selectedKeys={selectedKeys} className="productsMenu"
                    items={[
                        {
                            label:<Partner/>,
                            key:'/channel_partners',   
                        },
                    ]} />                 
                </div>

                <div className='d-flex flex-column pad20 w-25'>
                    <Title level={4} className="MenuTitle">Platform Partner</Title>
                    <Menu onClick={onMenuItemClick} selectedKeys={selectedKeys} className="productsMenu"
                    items={[
                        {
                            label:<PlatformPartner/>,
                            key:'/partner_platform',   
                        },
                    ]} />                 
                </div>

            </div>
            <div className="MenuBottomStrip">
                <div className="stripSVG">
                    <svg className="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 58">
                        <path d="M 101.23 0 H 110 V 58 H 0 V 58 H 5.78 C 19.93 56.5229 33.42 50.5238 42.9 40.0139 L 64.11 16.5089 C 73.59 5.9991 87.08 0 101.23 0 Z"></path>
                    </svg>
                    <div className='stripSVGBtm'></div>
                </div>
                <aside className="MenuStripAside">
                    <span>Deliver solutions along the user journey. 
                    <a target="_self" className="MenuStripLink" href="/contact">Contact sales</a></span>
                </aside>
            </div>
        </div> 
    );
}
function Banks(){
    return(
        <div className='MenuInnerLeft ml-0'>
            <img src={require('../../img/supply-sm.png')} style={{width:'100%'}} alt="banks"/>
            <div className='MenuSubContent'>One API network for all needs​</div>
        </div>
    )
}
function Insurance(){
    return(
        <div className='MenuInnerLeft ml-0'>
            <img src={require('../../img/insurance.png')} style={{width:'100%'}} alt="insurance"/>
            <div className='MenuSubContent'>One API network for all needs​</div>
        </div>
    )
}
function Partner(){
    return(
        <div className='MenuInnerLeft ml-0'>
            <img src={require('../../img/cPartner.png')} style={{width:'100%'}} alt="partner"/>
            <div className='MenuSubContent'>One platform for business management​​</div>
        </div>
    )
}
function PlatformPartner(){
    return(
        <div className='MenuInnerLeft ml-0'>
            <img src={require('../../img/ppartner.png')} style={{width:'100%'}} alt="Platform Partner"/>
            <div className='MenuSubContent'>One network, infinite monetization​​</div>
        </div>
    )
}
function MegaMenuProducts(){
    const [selectedKeys, setSelectedKeys] = useState (['']);
    const navigate = useNavigate();

    const onMenuItemClick = (e) => {
        navigate(e.key);
        setSelectedKeys([e.key]);
    }
    return(
        <div className='d-flex flex-column MegaMenu'>
            <div className='d-flex justify-space-between'>
                <div className='d-flex flex-column pad20 w-25'>
                    <Title level={4} className="MenuTitle">OneFlo</Title>
                    <Menu onClick={onMenuItemClick} selectedKeys={selectedKeys} className="productsMenu"
                    items={[
                        {
                            label:<OneFlo/>,
                            key:'/oneflo',   
                        },
                    ]} /> 
                </div>
                
                <div className='d-flex flex-column pad20 w-25'>
                    <Title level={4} className="MenuTitle">OneWay</Title>
                    <Menu onClick={onMenuItemClick} selectedKeys={selectedKeys} className="productsMenu"
                    items={[
                        {
                            label:<Svitch/>,
                            key:'/oneway',   
                        },
                    ]} />                 
                </div>

                <div className='d-flex flex-column pad20 w-25'>
                    <Title level={4} className="MenuTitle">OneBit</Title>
                    <Menu onClick={onMenuItemClick} selectedKeys={selectedKeys} className="productsMenu"
                    items={[
                        {
                            label:<Finety/>,
                            key:'/onebit',   
                        },
                    ]} />                 
                </div>

                <div className='d-flex flex-column pad20 w-25 MenuInnerRight'>
                    <Title level={4} className="MenuTitle">Product Announcements</Title>
                    <img src={require('../../img/announcement.png')} alt="announcement"/>
                    <div className='MenuSubContent'>Launching insurance across categories- Health, General and Medical.</div>
                </div>
            </div>
            <div className="MenuBottomStrip">
                <div className="stripSVG">
                    <svg className="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 58">
                        <path d="M 101.23 0 H 110 V 58 H 0 V 58 H 5.78 C 19.93 56.5229 33.42 50.5238 42.9 40.0139 L 64.11 16.5089 C 73.59 5.9991 87.08 0 101.23 0 Z"></path>
                    </svg>
                    <div className='stripSVGBtm'></div>
                </div>
                <aside className="MenuStripAside">
                    <span>Deliver solutions along the user journey. 
                    <a target="_self" className="MenuStripLink" href="/contact">Contact sales</a></span>
                </aside>
            </div>
        </div> 
    );
}

function OneFlo(){
    return(
        <div className='MenuInnerLeft ml-0'>
            <img src={require('../../img/1flo.png')} style={{width:'100%'}} alt="oneflo"/>
            <div className='MenuSubContent'>Filter, fulfill, cross-sell​</div>
        </div>
    )
}
function Svitch(){
    return(
        <div className='MenuInnerLeft ml-0'>
            <img src={require('../../img/1waylogo.png')} style={{width:'100%'}} alt="oneway"/>
            <div className='MenuSubContent'>Connect channel partners in hours​​</div>
        </div>
    )
}
function Finety(){
    return(
        <div className='MenuInnerLeft ml-0'>
            <img src={require('../../img/1Bitlogo.png')} style={{width:'100%'}} alt="onebit"/>
            <div className='MenuSubContent'>Create forms, journeys & products ​​</div>
        </div>
    )
}

function MegaMenu(){
    const [selectedKeys, setSelectedKeys] = useState (['']);
    const navigate = useNavigate();

    const onMenuItemClick = (e) => {
        navigate(e.key);
        setSelectedKeys([e.key]);
    }
    return(
        <div className='d-flex flex-column MegaMenu'>
            <div className='d-flex justify-space-between'>
                <div className='d-flex flex-column pad20 w-25'>
                    <Title level={4} className="MenuTitle">Manage</Title>
                    <Menu onClick={onMenuItemClick} selectedKeys={selectedKeys} 
                    items={[
                        {   
                            label: 'Customer Management',
                            key: '/manage/0',
                            icon:<Lead/>
                        },
                        {
                            label: 'Channel Management',
                            key: '/manage/1',
                            icon:<Channel/>
                        },
                        {
                            label: 'Team Management',
                            key: '/manage/2',
                            icon:<Operational/>
                        },
                    ]} />
                    <div className='MenuInnerLeft visibilityHidden'>
                        <div className='MenuSubContent'>Customer acquisition cost and Lifetime value of customers decide the growth trajectory 
                        of businesses. Qualify and filter leads better with right data and analysis. Lower the operational cost, source better 
                        quality leads, serve customers better. ​ ​</div>
                    </div>
                </div>
                <div className='d-flex flex-column pad20 w-25'>
                    <Title level={4} className="MenuTitle">Integrate</Title>
                    <Menu onClick={onMenuItemClick} selectedKeys={selectedKeys}
                        items={[
                            {
                                label:'Bank/Insurance APIs',
                                key:'/integrate/0',
                                icon:<Automation/>,
                            },
                            {   
                                label: 'Embedded Solution',
                                key: '/integrate/1',
                                icon:<Embedded/>,
                            },
                            {
                                label: 'Workflow Solutions',
                                key: '/integrate/2',
                                icon:<Access/>,
                            },
                        ]} />
                    <div className='MenuInnerLeft visibilityHidden'>
                        <div className='MenuSubContent'>Many systems, many silos and many frustrated customers. 
                        Keep the customer journey intact while delivering product along the way. 
                        In fact, improve those journeys through process automations and integrated workflows. ​</div>
                    </div>
                </div>
                
                <div className='d-flex flex-column pad20 w-25'>
                    <Title level={4} className="MenuTitle">Connect</Title>
                    <Menu onClick={onMenuItemClick} selectedKeys={selectedKeys} 
                    items={[
                        {   
                            label: 'Whatsapp/E-mail Connector',
                            key: '/connect/0',
                            className:'whatsapp',
                            icon:<WhatsApp/>
                        },
                        {
                            label: 'Ecosystem Creation​',
                            key: '/connect/1',
                            icon:<Lending/>
                        },
                        {
                            label: 'Offer/Product Updates',
                            key: '/connect/2',
                            icon:<NewProduct/>
                        },
                    ]} />
                    <div className='MenuInnerLeft visibilityHidden'>
                        <div className='MenuSubContent'>Networks are the a thing of beauty and their effects transform businesses. 
                        Join the network to have your products delivered to millions of new customers. Also, deliver values of the network 
                        to your customers.​</div>
                    </div>
                </div>

                <div className='d-flex flex-column pad20 w-25'>
                    <Title level={4} className="MenuTitle">Decide</Title>
                    <Menu onClick={onMenuItemClick} selectedKeys={selectedKeys} 
                    items={[
                        {   
                            label: 'Bank Statement Analysis',
                            key: '/decide/0',
                            icon:<Recommendation/>
                        },
                        {
                            label: 'Business Rule Engines',
                            key: '/decide/1',
                            icon:<Rule/>
                        },
                        {
                            label: 'Bureau Services',
                            key: '/decide/2',
                            icon:<VAS/>
                        },
                    ]} />
                    <div className='MenuInnerLeft visibilityHidden'>
                        <div className='MenuSubContent'>Decisions are driven by data & its analysis, in real time with the right context. 
                        And these decision making needs to be customized. Now, get access to the traditional and alternative data sources 
                        and the tools to process them.​ ​</div>
                    </div>
                </div>                
            </div>
            <div className="MenuBottomStrip">
                <div className="stripSVG">
                    <svg className="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 58">
                        <path d="M 101.23 0 H 110 V 58 H 0 V 58 H 5.78 C 19.93 56.5229 33.42 50.5238 42.9 40.0139 L 64.11 16.5089 C 73.59 5.9991 87.08 0 101.23 0 Z"></path>
                    </svg>
                    <div className='stripSVGBtm'></div>
                </div>
                <aside className="MenuStripAside">
                    <span>Deliver solutions along the user journey. 
                    <a target="_self" className="MenuStripLink" href="/contact">Contact sales</a></span>
                </aside>
            </div>
        </div> 
    );
}

const rootSubmenuKeys = ['partners','products', 'solutions'];

const Head = () =>  {
    const [current, setCurrent] = useState('');
    const [openKeys, setOpenKeys] = useState(['']);
    const [fix, setFix] = useState(false);
    const navigate = useNavigate();

    const menuClick = (e) => {
       if(e.key !== '/MegaMenu' && e.key !== '/MegaMenuProducts' && e.key !== '/MegaMenuPartners'){
        navigate(e.key);
        setCurrent(e.key);
       }
      
    };

    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const onOpenMenuChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
          setOpenKeys(keys);
        } else {
          setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    
    function setFixed(){
        if (window.scrollY >= 100){
            setFix(true);
        }
        else{
            setFix(false);
        }
    }
    window.addEventListener('scroll', setFixed);
    return (
        <Header className={fix ? 'fixed' : ''}>
            <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32 }} className="align-items-center">
                <Col className="gutter-row" xs={{span:12}} md={{span:12}} lg={{span: 3}}>
                    <Link to='/'><img src={logo} style={{height:'50px', marginTop:'-4px'}} alt="logo"/></Link>
                </Col>
                <Col className="gutter-row" xs={{span:12}} md={{span:12}} lg={{span: 21}}>
                    <div className='d-none-xs d-none-sm'>
                        <Menu className='mainMenu' onClick={menuClick} defaultSelectedKeys={[window.location.pathname]}  mode="horizontal" items={items}/>
                    </div>
                    <div className='d-visible-xs d-visible-sm'>
                        <div className='btn-drawer' onClick={showDrawer}>
                            <em></em>
                            <em></em>
                            <em></em>
                        </div>
                        <Drawer placement="right" onClose={onClose} open={open} closable={false} width={300}>
                            {<Menu onClick={menuClick} openKeys={openKeys} onOpenChange={onOpenMenuChange} mode="inline" items={MobItems} />}
                            <div className='signINLinks'>Sign In</div>
                            <a href='https://connector.oneflo.in/sign-in' className='mobLogin menuLink' target='_blank' rel="noopener noreferrer"><img src={require('../../img/1flo-sm.png')} alt=''/> <span>OneFlo Connector</span></a>
                            <a href='https://oneflo.in' className='mobLogin menuLink' target='_blank' rel="noopener noreferrer"><img src={require('../../img/1flo-team.png')} alt=''/> <span>OneFlo Team</span></a>
                        </Drawer>
                    </div>
                    <div className='userButtons'>
                        <a href='/' className='d-none-xs d-none-sm' rel="noopener noreferrer"><span>Sign In</span>
                            <div className='dropdownMenu'>
                                <a href="https://connector.oneflo.in" className='menuLink' target='_blank'><img src={require('../../img/1flo-sm.png')} alt=''/> <span>OneFlo Connector</span></a>
                                <a href="https://oneflo.in" className='menuLink' target='_blank'><img src={require('../../img/1flo-team.png')} alt=''/> <span>OneFlo Team</span></a>
                            </div>
                        </a>
                        <Link to="/contact" className='signUpBtn d-none d-none-xs d-none-sm'><span>Sign Up</span></Link>
                    </div>
                </Col>
            </Row>
        </Header>       
    )
}

export default Head;
