import React from 'react';
import { Layout, Typography, Row, Col } from 'antd';
import Head from '../Header/Header';
import Foot from '../Footer/Footer';
import { Link } from "react-router-dom";

const { Content } = Layout;
const { Title } = Typography;

export default function About() {
  return (
    <>
    <Head />
    <Layout className='white'>
      <Content>
          <div className="site-layout-content">
            <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center">
            <Title level={2} className="sectionTitle text-center">Fragmented financial ecosystems</Title>
              <Col xs={{span:24}} md={{span:12}} lg={{span: 8}}>
                <div className='abtSec'>
                  <Title level={3} className="sectionMTitle">Data silos persisted</Title> 
                  <div className='description'>
                    <ul className='pl15'>
                        <li>Data through alternate sources still untapped and recorded in various places</li>
                    </ul>
                  </div>  
                </div>
                <div className='abtSec'>
                  <Title level={3} className="sectionMTitle">Distribution silos multiplied</Title>
                  <div className='description'>
                    <ul className='pl15'>
                        <li>Inability to aggregate customers resulted in ever longer distribution chain</li>
                    </ul>
                  </div>  
                </div>
                <div className='abtSec'>
                  <Title level={3} className="sectionMTitle">Underwriting became restricted</Title>
                  <div className='description'>
                    <ul className='pl15'>
                        <li>Inability to create & launch customized new products & services</li>
                    </ul>
                  </div>  
                </div>
                <div className='abtSec'>
                  <Title level={3} className="sectionMTitle">'In location' solutions missing</Title>
                  <div className='description'>
                    <ul className='pl15'>
                        <li>Solutions need to be embedded in customer journeys via deeper integration</li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col xs={{span:24}} md={{span:12}} lg={{span: 16}} className='text-center'>
                <img src={require('../../img/globe-disconnect.png')} className="globe" alt='world'/>
              </Col>
            </Row>
          </div>
      </Content>
    </Layout>
    <Layout className='white'>
      <Content>
          <div className="site-layout-content">
            <Title level={2} className="sectionTitle text-center d-none-xs">Pain of every stakeholder only multiplied</Title>
            <Title level={2} className="sectionTitle text-center d-visible-xs mb-40">Pain of every stakeholder only multiplied</Title>
            <Row justify="space-around" align='middle' gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
              <Col xs={{span:24}} md={{span:12}} lg={{span: 8}}>
                  <img src={require('../../img/cps.png')} style={{width:'100%'}} className="mb-20" alt=''/>
              </Col>
              <Col xs={{span:24}} md={{span:12}} lg={{span: 8}}>
                <div className='description' style={{width:'100%', fontSize:'18px'}}>
                  <ul>
                    <li>Inability to take ﬁltration decision</li>
                    <li>Low conversion, high ops cost </li>
                    <li>High acquisition & servicing cost </li>
                    <li>Low margin, high WC requirement</li>
                  </ul>
                </div>
              </Col>
              <Col xs={{span:24}} md={{span:12}} lg={{span: 8}}>
                  <img src={require('../../img/abt_compementorl.png')} style={{width:'100%'}} className="mb-20" alt=''/>
              </Col>
            </Row>
            <div className='height50'></div>
            <Row justify="space-around" align='middle' gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
              <Col xs={{span:24}} md={{span:8}} lg={{span: 8}}>
                  <img src={require('../../img/fis.png')} style={{width:'100%'}} className="mb-20" alt='world'/>
              </Col>
              <Col xs={{span:24}} md={{span:8}} lg={{span: 8}}>
                <div className='description' style={{width:'100%', fontSize:'18px'}}>
                  <ul>
                    <li>No differentiation in offerings </li>
                    <li>Lack of end usage control </li>
                    <li>High servicing cost  </li>
                    <li>Inability to ﬁnd right product </li>
                    <li>Prolonged access process</li>
                  </ul>
                </div>
              </Col>
              <Col xs={{span:24}} md={{span:8}} lg={{span: 8}}>
                  <img src={require('../../img/abt_customers.png')} style={{width:'100%'}} className="mb-20" alt=''/>
              </Col>
            </Row>
          </div>
      </Content>
    </Layout>
    <Layout className='white'>
      <Content>
          <div className="site-layout-content">
            <Row justify="space-around" align='middle' gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
              <Col xs={{span:24}} md={{span:24}} lg={{span: 24}} className='text-center'>
                  <Title level={2} className="sectionTitle text-center d-none-xs">Network that enables delivery of Financial Solution along any transaction journey</Title>
                  <Title level={2} className="sectionTitle text-center d-visible-xs mb-40">Network that enables delivery of Financial Solution along any transaction journey</Title>
                  <img src={require('../../img/globe-connected.png')} className="mb-20 globe-sm" alt=''/>
              </Col>
            </Row>
          </div>
      </Content>
    </Layout>
    <Layout className='grey'>
      <Content>
        <div className="site-layout-content">
          <Title level={2} className="sectionTitle text-center d-none-xs">Enabling every Distribution Channel to be a FinTech</Title>
          <Title level={2} className="sectionTitle text-center d-visible-xs mb-40">Enabling every Distribution Channel to be a FinTech</Title>
          <Row justify="space-around" align='top' gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
            <Col xs={{span:24}} md={{span:24}} lg={{span: 10}}>
              <img src={require('../../img/visa.png')} style={{width:'100%'}} className="mb-20" alt=''/>
            </Col>
            <Col xs={{span:24}} md={{span:24}} lg={{span: 4}}></Col>
            <Col xs={{span:24}} md={{span:24}} lg={{span: 10}}>
              <img src={require('../../img/amazon.png')} style={{width:'100%'}} className="mb-20" alt=''/>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
    <Foot />
    </>
  )
}
