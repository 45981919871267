import React from "react";
import { Layout,Typography, Row, Col } from 'antd';
import { Link } from "react-router-dom";

const { Title } = Typography;
const { Content } = Layout;

const CallToAction = () => {
    return(
        <>
            <Layout className='grey'>
                <Content>
                    <div className="site-layout-content">
                        <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center">
                            <Col xs={{span:24}} md={{span:12}} lg={{span: 10}}>
                                <Title level={2} className="sectionTitle text-left">Set the network <br/> to work for you</Title>
                                <Link to="/contact" className='btn-orange mt-xs-15'>Contact Us</Link>
                            </Col>
                            <Col xs={{span:24}} md={{span:12}} lg={{span: 14}}>
                                <img src={require('../img/finety-b.png')} style={{width:'100%'}} alt="contact"/>
                            </Col>
                        </Row>
                    </div>
                </Content>
            </Layout>
        </>
    );
}

export default CallToAction;