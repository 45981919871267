import React, {useEffect, useRef, useState} from 'react'
import Head from '../Header/Header'
import Foot from '../Footer/Footer'
import { Layout, Typography, Menu, Row, Col } from 'antd';
import { useNavigate, useLocation } from "react-router-dom";
import {ReactComponent as Lead} from '../../img/icons/lead.svg';
import {ReactComponent as Channel} from '../../img/icons/channel.svg';
import {ReactComponent as Operational} from '../../img/icons/operationaleffi.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const { Content } = Layout;
const { Title } = Typography;

const Manage = () => {

  return (
    <div className="App">
      <Carousel />
    </div>
  );
}

const tabs = [
  {
    tag:"01",
    icon:<Lead />,
    title: "Customer Management",
    Content: "Manage the customer across the lifecycle of the relationship. Qualify and filter leads with the best tools, map customers to the right product, match them to the right FIs. Effectively, better conversion and customer experience at lower cost. And yes, then the icing on the cake is cross sell"
  },
  {
    tag:"02",
    icon:<Channel />,
    title: "Channel Management",
    Content: "Whether a financial institution or a channel partner, financial services thrive on distribution networks. Now, the CRM tool to centrally manage the network. Define hierarchies, products, priorities and track the same via dashboards. And then witness massive efficiencies when the OneFlo system integrates with any LOS"
  },
  {
    tag:"03",
    icon:<Operational />,
    title: "Team Management",
    Content: "Teams remain paramount to the success of any business. But large teams across geographies, motivations, products and partners require the most powerful team management solution. Define team roles, permissions, KRAs and track as per customized requirements. And empower the teams with the best banking analysis tools"
  },
];

const postImages = [
  require("../../img/channel-management.png"),
  require("../../img/channel-management.png"),
  require("../../img/channel-management.png"),
];

function Carousel() {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [selectedKeys, setSelectedKeys] = useState (['']);
  const navigate = useNavigate();
  const location = useLocation()
  const ref = useRef();
  useEffect(() => {
    //restartTimer();
    const index = parseInt(location.pathname.replace('/manage/', ""))
    if (currentIndex === -1) {
      setTimeout(() => {
        setCurrentIndex(index);
      }, 10);
    }else{
      setCurrentIndex(index);
    }

    return () => {
      setCurrentIndex(-1);
      clearInterval(ref.current);
    };
  }, [location.pathname]);

  // const restartTimer = () => {
  //   clearInterval(ref.current);
  //   ref.current = setInterval(() => {
  //     setCurrentIndex((pre) => {
  //       return (pre + 1) % tabs.length;
  //     });
  //   }, 5000);
  // };

  const onMenuItemClick = (e) => {
    navigate(e.key);
    setSelectedKeys([e.key]);
  }

  return (
    <>
    <Head />
    <Layout className='white' style={{minHeight:'100vh'}}>
      <Content style={{padding:'0px'}}>
        <div className="site-layout-content tab_content">
        <Row justify="space-between" gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
          <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
          
            <div className="tab_wrap">
            <Menu onClick={onMenuItemClick} selectedKeys={selectedKeys} className="productsMenu solutionsMenu"
              items={[
                  {
                    label:'Manage',
                    key:'/manage/0',  
                    className:'active',   
                  },
                  {
                    label:'Integrate',
                    key:'/integrate/0', 
                  },
                  {
                    label:'Connect',
                    key:'/connect/0',   
                  },
                  {
                    label:'Decide',
                    key:'/decide/0',   
                  },                  
              ]} /> 
              <Title level={2}>Manage</Title>
              <p className='d-none'>Customer acquisition cost and Lifetime value of customers decide the growth trajectory of businesses​. Feel the difference.</p>
              {tabs.map((tab, index) => {
                return (
                  <div
                    key={index}
                    className={`tab ${currentIndex === index ? "active" : ""}`}
                    onClick={() => {
                      setCurrentIndex(index);
                     // restartTimer();
                    }}
                  >
                    <div className='d-flex'>
                      <span className="Tabtag">{tab.icon}</span>
                    <div>
                      <h2 className="post_title">{tab.title}</h2>
                      <span className='post_content'>{tab.Content}</span>
                    </div>
                    </div>
                  </div>
                );
              })}
            </div>
            </Col>
            <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
            <header className="content_wrap">
              <div className="box">
                <div className="content_image">
                  {/* <img src={postImages[currentIndex]} style={{ width: "100%" }} alt="manage" /> */}
                  <LazyLoadImage src={require('../../img/1flo1.png')}  effect="blur" style={{ width: "100%", marginTop:'40px' }} alt="1flo"/>
                </div>
              </div>
            </header>
            </Col>
            </Row>
        </div>
      </Content>
    </Layout>
    <Foot />
    </>
  );
}


export default Manage;
