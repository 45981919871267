import React, { useLayoutEffect, useState } from 'react';
import { Layout, Typography, Row, Col, Tabs } from 'antd';
import './Home.css';
import Foot from '../Footer/Footer';
import Head from '../Header/Header';
import Slider from '../Slider';
// import SliderDescription from '../SliderDescription';
import { useParallax, Parallax } from 'react-scroll-parallax';
import { Link } from "react-router-dom";
import classnames from "classnames";
import CallToAction from '../CallToAction';
// data 
import data from '../../data/data.json'

//components
import Count from '../Count';
const { Content } = Layout;
const { Title } = Typography;

const images = [0, 1, 2];

export default function Home() {
    // const parallax = useParallax({speed:50});
    // const parallaxf = useParallax({speed:50});
    // const parallaxs = useParallax({speed:45});
    
    const [visibleImagesMap, setVisibleImagesMap] = useState(
        images.reduce((map, image) => {
          map[image] = false;
          return map;
        }, {})
    );
    useLayoutEffect(() => {
    const handleScroll = () => {
        const scrollTop = document.documentElement.scrollTop;
        const viewportHeight = window.innerHeight;
        //console.log('height', viewportHeight);
        const newVisibleImagesMap = images.reduce((map, image) => {
            map[image] = scrollTop >= image * viewportHeight;
            return map;
        }, {});

        setVisibleImagesMap(newVisibleImagesMap);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const renderContent = () => (
        <>
            <Parallax className="h100" translateY={[20, 0]}>
                <Title level={2} className="sectionMTitle">Banks & NBFCs</Title>   
                <div className='description'>
                    <ul className='pl15'>
                        <li>Reach of 5 Cr customers, 15,000+ Channel Partners</li>
                        <li>End to end fulfilment from origination to disbursement</li> 
                        <li>Pan India presence across 80+ geographies</li>
                        <li>Enriched lead qualification, 3x higher conversion</li>
                        <li>30+ D2C journeys for straight through processing</li>
                    </ul>
                </div>   
                <Link className='btn btn-white' to='/banks'>Learn More</Link> 
            </Parallax> 
            <Parallax className="h100" translateY={[10, 0]}>
                <Title level={2} className="sectionMTitle">Channel Partners</Title>
                <div className='description'>
                    <ul className='pl15'>
                        <li>Connect to 100+ Banks and Insurers digitally within minutes</li>
                        <li>5000+ financial products across assets, insurance & wealth</li>
                        <li>Customize BREs for better conversion & faster facilitation</li>
                        <li>Access Bureau, Bank/Financial statement analysis at one place</li>
                        <li>Manage your connectors & employees through OI Mobile App</li>
                    </ul>
                </div>
                <Link className='btn btn-white' to='/channel_partners'>Learn More</Link>
            </Parallax>  
            <Parallax className="h100" translateY={[5, 50]}>
                <Title level={2} className="sectionMTitle">Partner Platforms</Title> 
                <div className='description'>
                    <ul className='pl15'>
                        <li>Connect to 35 Banks and Insurers through APIs within minutes</li>
                        <li>5000+ financial products across assets, insurance & wealth</li>
                        <li>Embedded D2C 30+ journeys for straight through processing</li>
                        <li>End to end fulfilment from origination, filtration to disbursement</li>
                    </ul>
                </div> 
                <Link className='btn btn-white' to='/partner_platform'>Learn More</Link>     
            </Parallax> 
        </>
    );

    return (
    <>
    <Head />
    <div className="d-flex flex-column align-items-center justify-content-center sliderPnl">
        <Title className='mainTitle'>One Network to Access <Slider /> </Title>
        <Title className='Home_description d-none-xs' level={2}>Deliver infinite financial solutions through One Network</Title>
    </div>
    <div className='d-flex align-items-center justify-content-center dataPnl'> {data.counts.map(count => <Count key={count.id} data={count}/>)}</div>
    <Layout className='white parallax d-none-xs'>
        <Content className='d-flex parallax_w'>
            <div className="Parallax_content">{renderContent()}</div>
            <div className='sticky_container'>
                <div className='sticky'>      
                    <div className="frame">
                        {images.map((image) => (
                            <div
                            className={classnames("image", `image_${image}`, {
                                image_visible: visibleImagesMap[image]
                            })}
                            key={image}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </Content>
    </Layout>
    <Layout className='white d-visible-xs parallax-mob'>
        <Content>
            <div className="site-layout-content">
                <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="mb-150 align-items-center">
                    <Col xs={{span:24}}>
                        <Title level={3} className="sectionTitle">Banks & NBFCs</Title>
                    </Col>
                    <Col xs={{span:24}}>
                        <div className='description' style={{width:'100%'}}>
                            <ul className='pl15'>
                                <li>Reach of  5 Cr customers, 15,000+ CPs</li>
                                <li>End to end fulfilment till disbursement</li> 
                                <li>Presence across 80+ geographies</li>
                                <li>Enriched lead, 3x higher conversion</li>
                                <li>30+ D2C journeys for STP</li>
                            </ul>
                        </div>
                        <Link className='btn-txt' to='/banks'>Learn More...</Link>  
                    </Col>
                    <Col xs={{span:24}}>
                        <img src={require('../../img/banks_nbfcs.png')} className="pdImg" alt='banks'/>
                    </Col>
                </Row>
                <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="mb-150 align-items-center">
                    <Col xs={{span:24}}>
                        <Title level={3} className="sectionTitle">Channel Partners</Title>
                    </Col>
                    <Col xs={{span:24}}>
                        <div className='description' style={{width:'100%'}}>
                            <ul className='pl15'>
                                <li>Connect to 100+ Banks and Insurers</li>
                                <li>5000+ financial products across classes</li>
                                <li>Customize BREs for better conversion</li>
                                <li>Access Bureau, Bank/Financial analysis</li>
                                <li>Manage teams through OI Mobile App</li>
                            </ul>
                        </div>
                        <Link className='btn-txt' to='/channel_partners'>Learn More...</Link>  
                    </Col>
                    <Col xs={{span:24}}>
                        <img src={require('../../img/channel.png')} className="pdImg" alt="channel partners"/>
                    </Col>
                </Row>
                <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="mb-150 align-items-center">
                    <Col xs={{span:24}}>
                        <Title level={3} className="sectionTitle">Partner Platforms</Title>
                    </Col>
                    <Col xs={{span:24}}>
                        <div className='description' style={{width:'100%'}}>
                            <ul className='pl15'>
                                <li>Connect to 35 Banks and Insurers</li>
                                <li>5000+ financial products across classes</li>
                                <li>Embedded D2C 30+ journeys for STP</li>
                                <li>End to end fulfilment till disbursement</li>
                            </ul>
                        </div>
                        <Link className='btn-txt' to='/partner_platform'>Learn More...</Link>  
                    </Col>
                    <Col xs={{span:24}}>
                        <img src={require('../../img/partner.png')} className="pdImg" alt='partner'/>
                    </Col>
                </Row>
            </div>
        </Content>
    </Layout>
    <Layout className='grey products'>
        <Content>
            <div className="site-layout-content">
                <Title level={2} className="sectionTitle text-center mb-80">Products</Title>
                <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="mb-150 align-items-center">
                    <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                        <Title level={3} className="sectionTitle text-left">OneFlo</Title>
                        <Title level={4} className="sectionSubTitle">Filter, Fulfill, Cross-sell</Title>
                        <div className='sectionpdDesc'>
                            <ul className='pl15 d-none-xs'>
                                <li>Filter all leads and use lender specific BRE</li>
                                <li>Engagement engine for effective cross sell</li>
                                <li>One API window to submit  & process all cases</li>
                            </ul>
                        </div>
                        <Link className='btn btn-white d-none-xs' to='/oneflo'>Learn More</Link> 
                    </Col>
                    <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                        <img src={require('../../img/1flo1.png')} className="pdImg" alt='oneflo'/>
                        <Link className='btn-txt btn-inline-txt d-none d-visible-xs' to='/oneflo'>Learn More...</Link>  
                    </Col>
                </Row>
                <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="mb-150 align-items-center">
                    <Col xs={{span:24}} md={{span:12}} lg={{span: 12}} className="order-xs-2">
                        <img src={require('../../img/1way.png')} className="pdImg" alt='oneway'/>
                        <Link className='btn-txt btn-inline-txt d-none d-visible-xs' to='/oneway'>Learn More...</Link>
                    </Col>
                    <Col xs={{span:24}} md={{span:12}} lg={{span: 12}} className="content-pd-lft">
                        <Title level={3} className="sectionTitle text-left">OneWay</Title>
                        <Title level={4} className="sectionSubTitle">Connect Channel Partners in hours</Title>
                        <div className='sectionpdDesc'>
                            <ul className='pl15 d-none-xs'>
                                <li>One API switchboard for FIs to connect Channel Partners </li>
                                <li>FIs can embed APIs into any interface, web or app</li>
                                <li>Straight through processing D2C journeys in real time</li>
                            </ul>
                        </div>
                        <Link className='btn btn-white d-none-xs' to='/oneway'>Learn More</Link> 
                    </Col>
                </Row>
                <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center" >
                    <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                        <Title level={3} className="sectionTitle text-left">OneBit</Title>
                        <Title level={4} className="sectionSubTitle">Create forms, journeys & products</Title>
                        <div className='sectionpdDesc'>
                            <ul className='pl15 d-none-xs'>
                                <li>Design customer journeys to suit your customer needs</li>
                                <li>Create and test user and product specific BREs</li>
                                <li>Build customized products and test pre-launch</li>
                            </ul>
                        </div>
                        <Link className='btn btn-white d-none-xs' to='/onebit'>Learn More</Link> 
                    </Col>
                    <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                        <img src={require('../../img/1bit.png')} className="pdImg" alt='onebit'/>
                        <Link className='btn-txt btn-inline-txt d-none d-visible-xs' to='/onebit'>Learn More...</Link>
                    </Col>
                </Row>
            </div>
        </Content>
    </Layout>
    <Layout className='white'>
        <Content>
            <div className="site-layout-content">
                <Title level={2} className="sectionTitle text-center">Solutions</Title>
                <Tabs centered  className='centeredTabs' defaultActiveKey="1"
                    items={[
                    {
                        label: `Manage`,
                        key: 'tab1',
                        children: (
                            <>
                                <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center mt-50">
                                    <Col xs={{span:24}} md={{span:10}} lg={{span: 10}}>
                                        <Title level={2} className="sectionUlTitle text-left">
                                            <ul>
                                                <li>Customer Management</li>
                                                <li>Channel Management</li>
                                                <li>Team Management</li>
                                            </ul>
                                        </Title>
                                        <div className='sectionDesc d-none-xs'>Customer acquisition cost and lifetime value decide trajectory of any business. Manage the productivity of resources, improve fulfillment and conversions. And in the process deliver the best customer experience at the most effective cost</div>
                                    </Col>
                                    <Col xs={{span:24}} md={{span:14}} lg={{span: 14}}>
                                        <img src={require('../../img/manage.png')} className="pdImg"y alt="manage"/> 
                                    </Col>
                                </Row>
                            </>
                        ),
                    },
                    {
                        label: `Integrate`,
                        key: 'tab2',
                        children: (
                            <>
                                <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center mt-50">
                                    <Col xs={{span:24}} md={{span:10}} lg={{span: 10}}>
                                        <Title level={2} className="sectionUlTitle text-left">
                                            <ul>
                                                <li>Bank/Insurance APIs</li>
                                                <li>Embedded Solution</li>
                                                <li>Workflow Solutions</li>
                                            </ul>
                                        </Title>
                                        <div className='sectionDesc d-none-xs'>Explore the magic of a connected Ecosystem. 
                                        Connect with any partner - Financial Institution, Platform or Channel partner, hassle free. Keep customer journeys intact while along the way delivering financial solutions</div>
                                    </Col>
                                    <Col xs={{span:24}} md={{span:14}} lg={{span: 14}}>
                                        <img src={require('../../img/integrate.png')} className="pdImg" alt="integrate"/>
                                    </Col>
                                </Row>
                            </>
                        ),
                    },
                    {
                        label: `Connect`,
                        key: 'tab3',
                        children: (
                            <>
                                <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center mt-50">
                                    <Col xs={{span:24}} md={{span:10}} lg={{span: 10}}>
                                        <Title level={2} className="sectionUlTitle text-left">
                                            <ul>
                                                <li>Whatsapp Connector</li>
                                                <li>Ecosystem Creation</li>
                                                <li>Offer/Product Update</li>
                                            </ul>
                                        </Title>
                                        <div className='sectionDesc d-none-xs'>Networks are a thing of beauty and their effects transform businesses. 
                                        Now, join a network that provides scale, variety of financial products, diverse lending and insurance partners and access to massive distribution. And all this through the easiest integration</div>
                                    </Col>
                                    <Col xs={{span:24}} md={{span:14}} lg={{span: 14}}>
                                        <img src={require('../../img/reach.png')} className="pdImg" alt="reach"/>
                                    </Col>
                                </Row>  
                            </>
                        ),
                    },
                    {
                        label: `Decide`,
                        key: 'tab4',
                        children: (
                            <>
                                <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center mt-50">
                                    <Col xs={{span:24}} md={{span:10}} lg={{span: 10}}>
                                        <Title level={2} className="sectionUlTitle text-left">
                                            <ul>
                                                <li>Bank Statement Analysis</li>
                                                <li>Business Rule Engines</li>
                                                <li>Bureau services</li>
                                            </ul>
                                        </Title>
                                        <div className='sectionDesc d-none-xs'>Decisions are driven by data, analysis and timing with the right context. Decide which financial solutions suit your customers, how are these to be delivered, through bank grade tools (bureau, bank analysis, BRE)
                                        </div>
                                    </Col>
                                    <Col xs={{span:24}} md={{span:14}} lg={{span: 14}}>
                                        <img src={require('../../img/decide.png')} className="pdImg" alt="decide"/> 
                                    </Col>
                                </Row>
                            </>
                        ),
                    },
                    

                  
                    ]}
                >
                </Tabs>
            </div>
        </Content>
    </Layout>

    {/* <Layout className='white cust-str'>
        <Content>
            <div className="site-layout-content">
                <Title level={2} className="sectionTitle text-center">Customer Stories</Title>
                <Row justify="space-around" gutter={[30]}>
                    <Col xs={{span:24}} md={{span:4}} lg={{span: 4}} className="text-center"><img src={require('../../img/yellow.webp')} style={{width:'200px', height:'200px', borderRadius:'100px'}} alt=""/></Col>
                    <Col xs={{span:24}} md={{span:4}} lg={{span: 4}} className="text-center"><img src={require('../../img/black.webp')} style={{width:'200px', height:'200px', borderRadius:'100px'}} alt=""/></Col>
                    <Col xs={{span:24}} md={{span:4}} lg={{span: 4}} className="text-center"><img src={require('../../img/yellow.webp')} style={{width:'200px', height:'200px', borderRadius:'100px'}} alt=""/></Col>
                    <Col xs={{span:24}} md={{span:4}} lg={{span: 4}} className="text-center"><img src={require('../../img/black.webp')} style={{width:'200px', height:'200px', borderRadius:'100px'}} alt=""/></Col>
                    <Col xs={{span:24}} md={{span:4}} lg={{span: 4}} className="text-center"><img src={require('../../img/yellow.webp')} style={{width:'200px', height:'200px', borderRadius:'100px'}} alt=""/></Col>
                </Row>
            </div>
        </Content>
    </Layout> */}
    <CallToAction></CallToAction>
    <Foot/>
    </>
  )
}
