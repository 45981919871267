import React from 'react';
import './App.css';
import { Routes, Route} from "react-router-dom";
import Home from './components/Home/Home';
import Oneflo from './components/Pages/Oneflo';
import Svitch from './components/Pages/Svicth';
import Finety from './components/Pages/Finety';
import Demand from './components/Pages/Demand';
import ChannelPartners from './components/Pages/ChannelPartners';
import PartnerPlatform from './components/Pages/PartnerPlatform';
import Solutions from './components/Pages/Solutions';
import Banks from './components/Pages/Banks';
import About from './components/Pages/About';
import Contact from './components/Pages/Contact';
import Privacy from './components/Pages/Privacy';
import Terms from './components/Pages/Terms';
import Calculator from './components/Pages/Calculator';
import GoToTop from './GoToTop';
import Help from './components/Pages/Help';
import { ParallaxProvider } from 'react-scroll-parallax';
import Integrate from './components/Pages/Integrate';
import Decide from './components/Pages/Decide';
import Connect from './components/Pages/Connect';
import Manage from './components/Pages/Manage';
import Develops from './components/Pages/Develops';
import Insurance from './components/Pages/Insurance';
import OneWay from './components/Pages/Oneway';
import OneBit from './components/Pages/Onebit';

function App() {
   
  return (
    <>
    <ParallaxProvider>
    <GoToTop>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="about" element={<About />} />
      <Route path="develops" element={<Develops/>} />
      <Route path="oneflo" element={<Oneflo/>} />
      <Route path="oneway" element={<OneWay/>} />
      <Route path="oneBit" element={<OneBit/>} />
      <Route path="svitch" element={<Svitch/>} />
      <Route path="finety" element={<Finety/>} />
      <Route path="integrate/0" element={<Integrate/>} />
      <Route path="integrate/1" element={<Integrate/>} />
      <Route path="integrate/2" element={<Integrate/>} />
      <Route path="decide/0" element={<Decide/>} />
      <Route path="decide/1" element={<Decide/>} />
      <Route path="decide/2" element={<Decide/>} />
      <Route path="connect/0" element={<Connect/>} />
      <Route path="connect/1" element={<Connect/>} />
      <Route path="connect/2" element={<Connect/>} />
      <Route path="manage/0" element={<Manage/>} />
      <Route path="manage/1" element={<Manage/>} />
      <Route path="manage/2" element={<Manage/>} />
      <Route path="demand" element={<Demand/>} />
      <Route path="channel_partners" element={<ChannelPartners/>}/>
      <Route path="partner_platform" element={<PartnerPlatform/>} />
      <Route path="solutions" element={<Solutions/>} />
      <Route path="banks" element={<Banks/>} />
      <Route path="insurance" element={<Insurance/>} />
      <Route path="about-us" element={<About/>} />
      <Route path="contact" element={<Contact/>} />
      <Route path="privacy-policy" element={<Privacy/>} />
      <Route path="terms-conditions" element={<Terms/>} />
      <Route path="calculator" element={<Help/>} />
      <Route path="calculator1" element={<Calculator/>} />
    </Routes>
    </GoToTop>
    </ParallaxProvider>
    </>
  );
}

export default App;
