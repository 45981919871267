import React from 'react';
import Head from '../Header/Header';
import Foot from '../Footer/Footer';
import { Layout, Typography, Row, Col } from 'antd';
import { Link } from "react-router-dom";
import {ReactComponent as Lead} from '../../img/icons/lead.svg';
import {ReactComponent as Channel} from '../../img/icons/channel.svg';
import {ReactComponent as Operational} from '../../img/icons/operationaleffi.svg';
import {ReactComponent as Lending} from '../../img/icons/lending.svg';
import {ReactComponent as Partners} from '../../img/icons/partners.svg';
import {ReactComponent as NewProduct} from '../../img/icons/newproductmanage.svg';
import {ReactComponent as Embedded} from '../../img/icons/embedded-solutions.svg';
import {ReactComponent as Automation} from '../../img/icons/automation.svg';
import {ReactComponent as Access} from '../../img/icons/access-gateway.svg';
const { Content } = Layout;
const { Title } = Typography;


export default function OneWay() {
  return (
    <>
      <Head />
      <Layout className='white'>
        <Content>
          <div className="site-layout-content">
            <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center">
              <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                <Title level={2} className="sectionTitle text-left">OneWay</Title>
                <div className='Titledescription'>Connect Channel partners in hours</div>
                <p className='mb-20 smWidth'>One integration that any FI will every need. The OI switchboard allows any channel partner to be connected within hours & not weeks</p>
                <Link to="/contact" className='btn-orange d-none-xs'>Contact Us</Link>
              </Col>
              <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                <img src={require('../../img/1way.png')} alt="1way" style={{width:'100%'}} />
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
      <Layout className='white'>
        <Content>
            <div className="site-layout-content">
              <Title level={2} className="sectionTitle text-center">Values for you</Title>
                <div className='d-flex flex-xs-column ant-row'>
                  <div className='productsPNL'>
                    <img src={require('../../img/tradeoff.png')} alt='tradeoff'/>
                    <Title level={4} className="sectionTitle text-left">No More Tradeoff</Title>
                    <p>When every distribution channels is a fintech, trade-off between future business and cost of integration is eliminated</p>
                  </div>
                
                  <div className='productsPNL'>
                    <img src={require('../../img/inbuild-int.png')} alt='inbuild-int'/>
                    <Title level={4} className="sectionTitle text-left">Massive Digitization</Title>
                    <p>One API ensures that all CPs can be integrated within hours without any cost and this means entire chain is digital</p>
                  </div>
                
                  <div className='productsPNL'>
                    <img src={require('../../img/visibility.png')} alt='visibility'/>
                    <Title level={4} className="sectionTitle text-left">Better Visibility</Title>
                    <p>Communication through API pipeline is real time, resulting in better tractability for all and saving those iriitating back & forth</p>
                  </div>

                  <div className='productsPNL'>
                    <img src={require('../../img/d2c.png')} alt='d2C'/>
                    <Title level={4} className="sectionTitle text-left">D2C Journeys</Title>
                    <p>One API that will deliver on any digital interface and enable innumerable D2C journeys for millions of  customers</p>
                  </div>
                </div>
            </div>
        </Content>
      </Layout>
      <Layout className='grey'>
        <Content>
          <div className="site-layout-content">
            <Title level={2} className="sectionTitle text-center">Benefits for you</Title>
            <div className='d-flex flex-xs-column ant-row'>
              <div className='productsPNL'>
                <img src={require('../../img/integration.png')} alt='integration'/>
                <Title level={4} className="sectionTitle text-left">One Integration</Title>
                <p>The only integration that is needed which when plugged in makes any partner live with in a matter of hours</p>
              </div>
              <div className='productsPNL'>
                <img src={require('../../img/cost.png')} alt='setup'/>
                <Title level={4} className="sectionTitle text-left">10X Cost Saving</Title>
                <p>Save 10X on every integration, means more partners onboarded at no cost & no loss of time</p>
              </div>
              <div className='productsPNL'>  
                <img src={require('../../img/scaleUp.png')} alt='scaleUp'/>
                <Title level={4} className="sectionTitle text-left">Amazing Growth</Title>
                <p>Plug-in & start growing in terms of network, Financial institutions & products at amazing pace</p>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
      <Layout className='white'>
        <Content>
            <div className="site-layout-content">
              <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center">
                <Col xs={{span:24}} md={{span:24}} lg={{span: 24}}>
                    <Title level={2} className="sectionTitle text-center">Set the network to work for you</Title>
                </Col>
                <Col xs={{span:24}} md={{span:24}} lg={{span: 24}}>
                  <Link className='extLink' to="/integrate/0"><Automation /> Bank/Insurance APIs</Link>
                  <Link className='extLink' to="/integrate/1"><Embedded /> Embedded Solutions</Link>
                  <Link className='extLink' to="/connect/0"><Partners /> Whatsapp/E-mail Connector</Link>
                </Col>
              </Row>
            </div>
        </Content>
      </Layout>
    <Foot />
    </>
  )
}
