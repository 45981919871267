import React from 'react';
import Head from '../Header/Header';
import Foot from '../Footer/Footer';
import { Layout, Typography, Row, Col } from 'antd';
import { Link } from "react-router-dom";
import {ReactComponent as Automation} from '../../img/icons/automation.svg';
import {ReactComponent as Embedded} from '../../img/icons/embedded-solutions.svg';
import {ReactComponent as Access} from '../../img/icons/access-gateway.svg';
import {ReactComponent as Lending} from '../../img/icons/lending.svg';
import {ReactComponent as Partners} from '../../img/icons/partners.svg';
import {ReactComponent as NewProduct} from '../../img/icons/newproductmanage.svg';

const { Content } = Layout;
const { Title } = Typography;

export default function PartnerPlatform() {
  return (
    <>
    <Head />
    <Layout className='white'>
        <Content>
            <div className="site-layout-content">
              <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center">
                <Col xs={{span:24}} md={{span:24}} lg={{span: 24}}>
                    <Title level={2} className="sectionTitle text-center">Increase customer LTV Reduce CAC</Title>
                    <div className="page_description d-none-xs">
                      <ul className='page_ul'>
                          <li>Connect to 100s of FIS</li>
                          <li>End to end fulfilment </li>
                          <li>Thousands of products </li>
                          <li>STP journey to sanction </li>
                          <li>Access to new customers</li>
                      </ul>
                    </div>
                </Col>
                <Col xs={{span:24}} md={{span:24}} lg={{span: 24}} className='sectionAlignRight'>
                  <Link className='extLink' to="/integrate/0"><Automation /> Bank/Insurance APIs</Link>
                  <Link className='extLink' to="/connect/0"><Partners /> Whatsapp/E-mail Connector</Link>
                  <Link className='extLink' to="/integrate/2"><Embedded /> Embedded Solutions</Link>
                  <Link className='extLink' to="/connect/1"><Lending /> Ecosystem Creation</Link>
                  <Link className='extLink' to="/integrate/2"><Access /> Workflow Solutions</Link>
                  <Link className='extLink' to="/connect/2"><NewProduct /> Offer/Product Update</Link>
                </Col>
                <Link to="/contact" className='btn-orange mt-50 mt-xs-0'>Contact Us</Link>
              </Row>
            </div>
        </Content>
      </Layout>
      <Layout className='white'>
        <Content>
            <div className="site-layout-content">
              <Title level={2} className="sectionTitle text-center d-none-xs">Focus on growth, use the network</Title>
              <Title level={2} className="sectionTitle text-center d-visible-xs mb-40">Focus on growth</Title>
              <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                <Col xs={{span:24}} md={{span:8}} lg={{span: 8}}>
                  <div className='br12 mb-20 text-center'>
                    <img src={require('../../img/embed-sol1.png')} style={{width:'80%'}} alt='Embedded-Solutions'/>
                  </div>
                    <Title level={4} className="sectionTitle text-left">Embedded Solutions</Title>
                    <p>Offer products along any user journey platform for- credit, investment, insurance & more through white labelled, iframe, re-direction. Then sit back as the OI backend engine & API switch delivers recurring value for your customers & you</p>
                 </Col>
                <Col xs={{span:24}} md={{span:8}} lg={{span: 8}}>
                  <div className='br12 mb-20 text-center'>
                    <img src={require('../../img/bpa.png')} style={{width:'80%'}} alt="BPA"/>
                  </div>
                    <Title level={4} className="sectionTitle text-left">Infinity Connections</Title>
                    <p>100s of integrations on Partner’s behalf have been created by OI. Now leverage the same through plug-in. This means multiple user journeys can be made live in your environment allowing you to connect with Banks, Insurers, Wealth & more</p>
                  </Col>
                <Col xs={{span:24}} md={{span:8}} lg={{span: 8}}>
                  <div className='br12 mb-20 text-center'>
                    <img src={require('../../img/partner-network.png')} style={{width:'80%'}} alt="Partner-Network"/>
                  </div>
                    <Title level={4} className="sectionTitle text-left">Partner Network</Title>
                    <p>OI gateway is a two way flow to provide offerings from partner network and at the same time offer your products through the network. Now have customers access all financial services & products. Also, explore how to reach out to new customers</p>
                </Col>
              </Row>
            </div>
        </Content>
      </Layout>
      <Layout className='grey'>
        <Content>
            <div className="site-layout-content">
              <Title level={2} className="sectionTitle text-center">Set the network to work for you</Title>
              <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                <Col xs={{span:24}} md={{span:24}} lg={{span: 24}} className="text-center footer_logos">
                  <img src={require('../../img/lenders/HDFC.png')} alt=''/>
                  <img src={require('../../img/lenders/icici.png')} alt=''/>
                  <img src={require('../../img/lenders/axis-bank.png')} alt=''/>
                  <img src={require('../../img/lenders/yes-bank.png')} alt=''/>
                  <img src={require('../../img/lenders/fineoteric-logo.png')} alt=''/>
                  <img src={require('../../img/lenders/rupeeboss.png')} alt=''/>
                  <img src={require('../../img/lenders/Royal Finserv Logo.jpg')} alt=''/>
                  <img src={require('../../img/lenders/Vattakosh.jpg')} alt=''/>
                </Col>
              </Row>
            </div>
        </Content>
      </Layout>
      {/* <Layout className='white'>
        <Content>
            <div className="site-layout-content">
              <Row justify="space-between" gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                <Col xs={{span:24}} md={{span:12}} lg={{span: 6}}>
                  <Title level={4}>Case Study</Title>
                </Col>
                <Col xs={{span:24}} md={{span:12}} lg={{span: 18}} className='d-flex justify-space-between'>
                  <div className='case_study'>
                    <Title level={5}>Project Year</Title>
                    <span>2022</span>
                  </div>
                  <div className='case_study'>
                    <Title level={5}>Bank Type</Title>
                    <span>Digital Greenfield</span>
                  </div>
                  <div className='case_study'>
                    <Title level={5}>Cloud Partner</Title>
                    <span>OpenShift and Google Cloud</span>
                  </div>
                  <div className='case_study'>
                    <Title level={5}>Geography</Title>
                    <span>India</span>
                  </div>
                </Col>
              </Row>
              <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="mt-50 align-items-center">
                <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                  <img src={require('../../img/dummy.jpg')} style={{width:'100%'}} alt=""/>
                </Col>
                <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                  <Title level={4} className="sectionTitle text-left">Challenges</Title>
                  <p style={{marginBottom:'50px'}}>Italy’s largest banking group, Intesa Sanpaolo, planned a four-year vision for the evolution of their organisation. 
                    This wide-ranging plan covered cost reduction, technology modernisation, customer experience and more. Intesa Sanpaolo 
                    sought a technology partner – in the truest sense of the word – a core banking vendor that had the technology 
                    credentials, the experience of working with Tier 1 banks, and the right architecture to be a close partner over 
                    the years ahead and truly modernise their service.</p>

                  <Title level={4} className="sectionTitle text-left">The Solution</Title>
                  <p>Italy’s largest banking group, Intesa Sanpaolo, planned a four-year vision for the evolution of their organisation. 
                    This wide-ranging plan covered cost reduction, technology modernisation, customer experience and more. Intesa Sanpaolo 
                    sought a technology partner – in the truest sense of the word – a core banking vendor that had the technology 
                    credentials, the experience of working with Tier 1 banks, and the right architecture to be a close partner over 
                    the years ahead and truly modernise their service.</p>
                </Col>
              </Row>
            </div>
        </Content>
      </Layout> */}
    <Foot />
    </>
  )
}
