import React, {useEffect, useRef, useState} from 'react'
import Head from '../Header/Header'
import Foot from '../Footer/Footer'
import { Menu, Layout, Typography, Row, Col } from 'antd';  
import { useLocation, useNavigate } from "react-router-dom";
import {ReactComponent as Automation} from '../../img/icons/automation.svg';
import {ReactComponent as Embedded} from '../../img/icons/embedded-solutions.svg';
import {ReactComponent as Access} from '../../img/icons/access-gateway.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const { Content } = Layout;
const { Title } = Typography;

const Integrate = () => {
  return (
    <div className="App">
      <Carousel />
    </div>
  );
}

const tabs = [
  {
    tag:"01",
    icon:<Automation/>,
    title: "Bank/Insurance APIs",
    Content: "APIs are the expressways of the digital world. But these are time consuming, complex & difficult to build. OI provides an infrastructure that allows access to 100s of APIs from Bank, NBFCs, Insurance, Wealth and ancillary financial services. This convenient switchboard is easy to configure and saves time and money for Financial Institutions, Partners & Fintechs"
  },
  {
    tag:"02",
    icon:<Embedded/>,
    title: "Embedded Solutions",
    Content: "Customer journeys are meant to be unbroken and seamless. And financial solutions are meant to be coupled with primary demand. OI presents a completely embedded user journey by integrating into any platform - mobile or app. This can be customized for an in-environment experience and handle data push to sanction of case"
  },
  {
    tag:"03",
    icon:<Access/>,
    title: "Workflow Solutions",
    Content: "Workflows are the key to customer experience. But no two customers' context is similar. Why the need to force fit customers to irrelevant journeys? Now, a flexible tool that allows for the creation and customization of workflows and integration with other systems for a friction-free experience"
  },
];

const postImages = [
  require("../../img/automation.png"),
  require("../../img/automation.png"),
  require("../../img/automation.png"),
];

function Carousel() {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [selectedKeys, setSelectedKeys] = useState (['']);
  const navigate = useNavigate();
  const location = useLocation()
  const ref = useRef();
  useEffect(() => {
    //restartTimer();
    const index = parseInt(location.pathname.replace('/integrate/', ""))
    if (currentIndex === -1) {
      setTimeout(() => {
        setCurrentIndex(index);
      }, 10);
    }else{
      setCurrentIndex(index);
    }

    return () => {
      setCurrentIndex(-1);
      clearInterval(ref.current);
    };
  }, [location.pathname]);

  // const restartTimer = () => {
  //   clearInterval(ref.current);
  //   ref.current = setInterval(() => {
  //     setCurrentIndex((pre) => {
  //       return (pre + 1) % tabs.length;
  //     });
  //   }, 5000);
  // };
  const onMenuItemClick = (e) => {
    navigate(e.key);
    setSelectedKeys([e.key]);
  }

  return (
    <>
    <Head />
    <Layout className='white' style={{minHeight:'100vh'}}>
      <Content style={{padding:'0px'}}>
        <div className="site-layout-content tab_content">
        <Row justify="space-between" gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
          <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
            <div className="tab_wrap">
            <Menu onClick={onMenuItemClick} selectedKeys={selectedKeys} className="productsMenu solutionsMenu"
              items={[
                  {
                    label:'Manage',
                    key:'/manage/0',   
                  },
                  {
                    label:'Integrate',
                    key:'/integrate/0',
                    className:'active',   
                  },
                  {
                    label:'Connect',
                    key:'/connect/0',   
                  },
                  {
                    label:'Decide',
                    key:'/decide/0',   
                  },                  
              ]} /> 
              <Title level={2}>Integrate</Title>
              <p className='d-none'>Many systems, many silos and many frustrated customers. Keep the customer journey intact while delivering product along the way.​</p>
              {tabs.map((tab, index) => {
                return (
                  <div
                    key={index}
                    className={`tab ${currentIndex === index ? "active" : ""}`}
                    onClick={() => {
                      setCurrentIndex(index);
                      //restartTimer();
                    }}
                  >
                    <div className='d-flex'>
                      <span className="Tabtag">{tab.icon}</span>
                    <div>
                      <h2 className="post_title">{tab.title}</h2>
                      <span className='post_content'>{tab.Content}</span>
                    </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Col>
          <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
            <div className="content_wrap">
              <article className="box">
                <div className="content_image">
                  <LazyLoadImage src={require('../../img/1way.png')}  effect="blur" style={{ width: "100%", marginTop:'40px' }} alt="1way"/>
                </div>
              </article>
            </div>
          </Col>
        </Row>
        </div>
      </Content>
    </Layout>
    <Foot />
    </>
  );
}


export default Integrate;
