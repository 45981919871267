import React from 'react'
import Head from '../Header/Header'
import Foot from '../Footer/Footer'
import ParallaxComp  from '../Parallax/ParallaxComp' 

const Solutions = () => {
  return (
    <>
    <Head />
    <ParallaxComp />
    <Foot />
    </>
  );
}

export default Solutions;
