import React from 'react';
import Head from '../Header/Header';
import Foot from '../Footer/Footer';
import { Layout, Typography, Row, Col } from 'antd';
import { Link } from "react-router-dom";
import {ReactComponent as Lead} from '../../img/icons/lead.svg';
import {ReactComponent as Channel} from '../../img/icons/channel.svg';
import {ReactComponent as Operational} from '../../img/icons/operationaleffi.svg';
import {ReactComponent as Lending} from '../../img/icons/lending.svg';
import {ReactComponent as Partners} from '../../img/icons/partners.svg';
import {ReactComponent as NewProduct} from '../../img/icons/newproductmanage.svg';
import {ReactComponent as Embedded} from '../../img/icons/embedded-solutions.svg';
import {ReactComponent as Access} from '../../img/icons/access-gateway.svg';
const { Content } = Layout;
const { Title } = Typography;


export default function OneFlo() {
  return (
    <>
      <Head />
      <Layout className='white'>
        <Content>
          <div className="site-layout-content">
            <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center">
              <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                <Title level={2} className="sectionTitle text-left">OneFlo</Title>
                <div className='Titledescription'>Filter, fulfill & cross sell</div>
                <p className='mb-20 smWidth'>Fully customizable CRM for financial distribution with APIs to 100 of FIs, partner platforms & services</p>
                <Link to="/contact" className='btn-orange d-none-xs'>Contact Us</Link>
              </Col>
              <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                <img src={require('../../img/1flo1.png')} alt="1flo" style={{width:'100%'}} />
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
      <Layout className='white'>
        <Content>
            <div className="site-layout-content">
              <Title level={2} className="sectionTitle text-center">Values for you</Title>
                <div className='d-flex flex-xs-column ant-row'>
                  <div className='productsPNL'>
                    <img src={require('../../img/cvp.png')} alt='cvp'/>
                    <Title level={4} className="sectionTitle text-left">Better Monetization</Title>
                    <p>Identify needs of customers & match them with right products, earning more while delivering great value</p>
                  </div>
                
                  <div className='productsPNL'>
                    <img src={require('../../img/inbuild-int.png')} alt='inbuild-int'/>
                    <Title level={4} className="sectionTitle text-left">Inbuild Integrations</Title>
                    <p>OneFlo is per-integrated with 100s of systems. Push leads/documents to FIs, Insurers and track their fulfillment</p>
                  </div>
                
                  <div className='productsPNL'>
                    <img src={require('../../img/decision-making.png')} alt='decision-making'/>
                    <Title level={4} className="sectionTitle text-left">Decision Making</Title>
                    <p>Many decisions are involved at the every step of financial journey. Now, customize how these decisions are executed</p>
                  </div>

                  <div className='productsPNL'>
                    <img src={require('../../img/vas.png')} alt='vas'/>
                    <Title level={4} className="sectionTitle text-left">Value Added Services</Title>
                    <p>Bank grade Bank/financial statement & GST analysis, Bureau, reconciliations, and many more for best conversions</p>
                  </div>
                </div>
            </div>
        </Content>
      </Layout>
      <Layout className='grey'>
        <Content>
          <div className="site-layout-content">
            <Title level={2} className="sectionTitle text-center">Benefits for you</Title>
            <div className='d-flex flex-xs-column ant-row'>
              <div className='productsPNL'>
                <img src={require('../../img/4x.png')} alt='sign-up'/>
                <Title level={4} className="sectionTitle text-left">4X Productivity</Title>
                <p>With the best tools for better lead scrubbing & filtration witness massive conversion improvement</p>
              </div>
              <div className='productsPNL'>
                <img src={require('../../img/setup.png')} alt='setup'/>
                <Title level={4} className="sectionTitle text-left">Easy Operations</Title>
                <p>Manage teams, partners & cases centrally. Operations at large scale were never this easy to control</p>
              </div>
              <div className='productsPNL'>  
                <img src={require('../../img/scaleUp.png')} alt='scaleUp'/>
                <Title level={4} className="sectionTitle text-left">Amazing Growth</Title>
                <p>Plug-in & start growing in terms of network, Financial institutions & products at amazing pace</p>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
      <Layout className='white'>
        <Content>
            <div className="site-layout-content">
              <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center">
                <Col xs={{span:24}} md={{span:24}} lg={{span: 24}}>
                    <Title level={2} className="sectionTitle text-center">Set the network to work for you</Title>
                </Col>
                <Col xs={{span:24}} md={{span:24}} lg={{span: 24}}>
                  <Link className='extLink' to="/manage/0"><Lead /> Customer Management</Link>
                  <Link className='extLink' to="/integrate/1"><Embedded /> Embedded Solutions</Link>
                  <Link className='extLink' to="/integrate/2"><Access /> Workflow Solutions</Link>
                </Col>
              </Row>
            </div>
        </Content>
      </Layout>
    <Foot />
    </>
  )
}
