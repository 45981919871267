import React, { useState } from 'react';
import Head from '../Header/Header';
import Foot from '../Footer/Footer';
import { Form, Button, Select, Input, Steps } from 'antd';
import { CheckCircleOutlined, LoginOutlined, ProfileOutlined } from '@ant-design/icons';

const { Option } = Select;

const Calculator = () => {
    const [current, setCurrent] = useState(0);
    const [loginDetails, setLoginDetails] = useState(null);
    const [profileDetails, setProfileDetails] = useState(null);
    const onFinishLoginForm = (values) => {
        setLoginDetails(values);
        console.log('login details', values);
        setCurrent(1)
    }
    const onFinishProfileForm = (values) => {
        setProfileDetails(values);
        console.log('Profile details', values);
        setCurrent(2)
    }

    const forms = [
        <LoginForm onFinish={onFinishLoginForm} initialValues={loginDetails}/>,
        <ProfileForm onFinish={onFinishProfileForm} initialValues={profileDetails}/>,
        <Finish/>
    ]

    const isStepDisabled = (stepNumber) => {
        if(stepNumber === 0){
            return false;
        }
        if(stepNumber === 1){
            return loginDetails === null;
        }
        if(stepNumber === 2){
            return loginDetails === null || profileDetails === null;
        }
    }

    return(
        <>
        <Head />
        <div style={{padding:'100px 50px'}}>
            <Steps 
                onChange={setCurrent}
                current={current}
            >
                <Steps.Step disabled={isStepDisabled(0)} title='Login' icon={<LoginOutlined />}></Steps.Step>
                <Steps.Step disabled={isStepDisabled(1)} title='Profile' icon={<ProfileOutlined />}></Steps.Step>
                <Steps.Step disabled={isStepDisabled(2)} title='Finish' icon={<CheckCircleOutlined />}></Steps.Step>
            </Steps>
            {forms[current]}
        </div>
        <Foot />
        </>
    )
} 

function LoginForm({onFinish, initialValues}){
    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select style={{ width: 70 }}>
            <Option value="91">+91</Option>
          </Select>
        </Form.Item>
    );
    return (
    <Form onFinish={onFinish} initialValues= {{initialValues, prefix:'91',}}>
        <Form.Item 
            label="Name"
            name={'name'}
            rules={[
            {
                required: true,
                message: "please enter your name",
            },
            ]}
        >
            <Input
            name= 'name'
            type="text"
            />
        </Form.Item>
        <Form.Item
            label="Mobile Number"
            name={'mobile'}
            rules={[
            {
                required: true,
                message: "please enter mobile number",
            },
            {
                min:10,
                max:10,
                message: "please enter 10 digit mobile number",
            },
            ]}
        >
            <Input
            name='mobile'
            addonBefore={prefixSelector}
            type="Number"
            />
        </Form.Item>
        <Button type='primary' htmlType='submit'>Continue</Button>
    </Form>
)}

function ProfileForm({onFinish, initialValues}){
    return (
    <Form onFinish={onFinish} initialValues={initialValues}>
        <Form.Item label="First Name" name={'fname'} rules={[{
            required:true,
            message:'please enter first name'
        }]}>
            <Input />
        </Form.Item>
        <Form.Item label="Last Name" name={'lname'} rules={[{
            required:true,
            message:'please enter last name'
        }]}>
            <Input />
        </Form.Item>
        <Button type='primary' htmlType='submit'>Continue</Button>
    </Form>
)}

function Finish(){
    return(
        <>
        <h1>you are all set</h1>
        <Button type='primary'>Finish</Button>
        </>
    )
}

export default Calculator;