import React from 'react'
import Head from '../Header/Header'
import Foot from '../Footer/Footer'
import { Layout, Typography, Row, Col } from 'antd';
import { Link } from "react-router-dom";
import CallToAction from '../CallToAction';

const { Content } = Layout;
const { Title } = Typography;


export default function Svitch() {
  return (
    <>
      <Head />
      <Layout className='white'>
        <Content>
            <div className="site-layout-content">
              <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center">
                <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                    <Title level={2} className="sectionTitle text-left">Svitch</Title>
                    <div className='Titledescription'>Svitch to the financial stack</div>
                    <Link to="/contact" className='btn-orange d-none-xs'>Contact Us</Link>
                </Col>
                <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                  <img src={require('../../img/svitch.png')} alt="svicth" width='100%' />
                </Col>
              </Row>
            </div>
        </Content>
      </Layout>
      <Layout className='white'>
        <Content>
            <div className="site-layout-content">
              <Title level={2} className="sectionTitle text-center">Values for you</Title>
                <div className='d-flex flex-xs-column ant-row'>
                  <div className='productsPNL'>
                    <img src={require('../../img/card.png')} alt='card'/>
                    <Title level={4} className="sectionTitle text-left">Card for all</Title>
                    <p>Every verified expense is funded. Card for employees against salaries accrued. Earn rewards on spends.</p>
                  </div>
                
                  <div className='productsPNL'>
                    <img src={require('../../img/fastest-management.png')} alt='fastest-management'/>
                    <Title level={4} className="sectionTitle text-left">Swiftest cash management</Title>
                    <p>Set up limits against assets and receivables, for individual expenses and every stakeholder.</p>
                  </div>
                
                  <div className='productsPNL'>
                    <img src={require('../../img/smart-expense.png')} alt='smart-expense'/>
                    <Title level={4} className="sectionTitle text-left">Smart expense management</Title>
                    <p>Allocate and control limits for all expenses. Track and reconcile in real time, into your accounting system.</p>
                  </div>
                </div>
            </div>
        </Content>
      </Layout>
      <Layout className='grey'>
        <Content>
            <div className="site-layout-content">
              <Title level={2} className="sectionTitle text-center">How it works</Title>
              <div className='d-flex flex-xs-column ant-row'>
                <div className='productsPNL'>
                  <img src={require('../../img/signUp.png')} alt='sign-up'/>
                  <Title level={4} className="sectionTitle text-left">Sign up</Title>
                  <p>Sign up for the program, and check specific eligibilities.</p>
                </div>
              
                <div className='productsPNL'>  
                  <img src={require('../../img/validation.png')} alt='validation'/>
                  <Title level={4} className="sectionTitle text-left">Validation</Title>
                  <p>Validate all necessary details by downloading the app.</p>
                </div>
              
                <div className='productsPNL'>  
                  <img src={require('../../img/transacting.png')} alt='transacting'/>
                  <Title level={4} className="sectionTitle text-left">Start transacting</Title>
                  <p>Use card or app across any location for rewards. </p>
                </div>
              </div>
            </div>
        </Content>
      </Layout>
      <div className='callToAction'><CallToAction /></div>
      <Foot />
    </>
  )
}
