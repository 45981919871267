import React from 'react';
import { Layout, Typography, Row, Col } from 'antd';
import Head from '../Header/Header';
import Foot from '../Footer/Footer';

const { Content } = Layout;
const { Title } = Typography;

export default function Develops() {

  return (
    <>
    <Head />
    <Layout className='grey'>
        <Content>
            <div className="site-layout-content">
                <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center">
                    <Col xs={{span:24}} md={{span:24}} lg={{span: 18}}>
                      <Title level={2} className="sectionTitle text-left" style={{margin:'0px'}}>Develops page</Title>
                    </Col>  
                    <Col xs={{span:24}} md={{span:24}} lg={{span: 6}}></Col>                  
                </Row>
            </div>
        </Content>
    </Layout>
    <Foot />
    </>
  )
}
