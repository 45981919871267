import React from 'react'
import Head from '../Header/Header'
import Foot from '../Footer/Footer'
import { Layout, Typography, Row, Col } from 'antd';
import { Link } from "react-router-dom";
import CallToAction from '../CallToAction';

const { Content } = Layout;
const { Title } = Typography;


export default function Finety() {
  return (
    <>
      <Head />
      <Layout className='white'>
        <Content>
            <div className="site-layout-content">
              <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center">
                <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                    <Title level={2} className="sectionTitle text-left">Finety</Title>
                    <div className='Titledescription'>Let us scale together</div>
                    <Link to="/contact" className='btn-orange d-none-xs'>Contact Us</Link>
                </Col>
                <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                  <img src={require('../../img/finety-b.png')} alt="svicth" width='100%' />
                </Col>
              </Row>
            </div>
        </Content>
      </Layout>
      <Layout className='white'>
        <Content>
            <div className="site-layout-content">
              <Title level={2} className="sectionTitle text-center">Values for you</Title>
              <div className='d-flex flex-xs-column ant-row'>
                <div className='productsPNL'>
                  <img src={require('../../img/reach-new-customers.png')} alt='reach new customers'/>
                  <Title level={4} className="sectionTitle text-left">Reach new customers</Title>
                  <p>Reach new customers at minimal marginal cost. Feel that network power as you scale with the partners.</p>
                </div>
              
                <div className='productsPNL'>  
                  <img src={require('../../img/clv.png')} alt='customer lifetime value'/>
                  <Title level={4} className="sectionTitle text-left">Increase lifetime value of customers</Title>
                  <p>Embed the solutions to match customer journey and generate additional revenue stream, boosting CLVs.</p>
                </div>
              
                <div className='productsPNL'>  
                  <img src={require('../../img/ease-customer-journey.png')} alt='ease customer journey'/>
                  <Title level={4} className="sectionTitle text-left">Ease customer journeys</Title>
                  <p>Plug into 100s of integrations and automations. Make journeys of customers simple, intutive and efficient.</p>
                </div>
              </div>
            </div>
        </Content>
      </Layout>
      <Layout className='grey'>
        <Content>
            <div className="site-layout-content">
              <Title level={2} className="sectionTitle text-center">How it works</Title>
              <div className='d-flex flex-xs-column ant-row'>
                <div className='productsPNL'>
                  <img src={require('../../img/signUp.png')} alt='sign-up'/>
                  <Title level={4} className="sectionTitle text-left">Sign up</Title>
                  <p>Sign up for the program, and customize offering for your requirements.</p>
                </div>
              
                <div className='productsPNL'>  
                  <img src={require('../../img/integrate_icon.png')} alt='integrate'/>
                  <Title level={4} className="sectionTitle text-left">Integrate</Title>
                  <p>Plug into OI network, switch on embedded solutions.</p>
                </div>
              
                <div className='productsPNL'>  
                  <img src={require('../../img/scaleUp.png')} alt='scaleUp'/>
                  <Title level={4} className="sectionTitle text-left">Scale up</Title>
                  <p>Explore new avenues of scale up by plugging in, now.</p>
                </div>
              </div>
            </div>
        </Content>
      </Layout>
      <div className='callToAction'>
        <CallToAction />
      </div>
      <Foot />
    </>
  )
}
