import React from "react";
import { Menu, Layout, Row, Col } from "antd";
import { Link } from "react-router-dom";
import "./Footer.css";
import Icon from '@ant-design/icons';

const { Footer } = Layout;
const LinkedIn = () => (
  <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 32 32" width="32px" height="32px">
    <path d="M 8.6425781 4 C 7.1835781 4 6 5.181625 6 6.640625 C 6 8.099625 7.182625 9.3085938 8.640625 9.3085938 C 10.098625 9.3085938 11.283203 8.099625 11.283203 6.640625 C 11.283203 5.182625 10.101578 4 8.6425781 4 z M 21.535156 11 C 19.316156 11 18.0465 12.160453 17.4375 13.314453 L 17.373047 13.314453 L 17.373047 11.310547 L 13 11.310547 L 13 26 L 17.556641 26 L 17.556641 18.728516 C 17.556641 16.812516 17.701266 14.960938 20.072266 14.960938 C 22.409266 14.960937 22.443359 17.145609 22.443359 18.849609 L 22.443359 26 L 26.994141 26 L 27 26 L 27 17.931641 C 27 13.983641 26.151156 11 21.535156 11 z M 6.3632812 11.310547 L 6.3632812 26 L 10.923828 26 L 10.923828 11.310547 L 6.3632812 11.310547 z"/>
  </svg>
);
const LinkedInIcon = (props) => <Icon component={LinkedIn} {...props} />;
const resources = [
  {
    label: (<Link to='/terms-conditions'>Terms & Conditions</Link>),
    key: 'terms',
  },
  {
    label: (<Link to='/privacy-policy'>Privacy Policy</Link>),
    key: 'policy',
  },
];

export default function Foot() {
  return (
    <Footer>
      <Row justify="space-between" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-top">
        <Col xs={{span:0}} md={{span:4}} lg={{span: 18}} className="d-none-xs text-left">
          <img src={require('../../img/logo-w.png')} style={{height:'55px', width:'auto'}} alt="logo"/>
        </Col>
        {/* <Col xs={{span:20}} md={{span:12}} lg={{span: 21}}>
          <Menu mode="horizontal" items={company} className="footer-menu" />
        </Col> */}
        <Col xs={{span:24}} md={{span:20}} lg={{span: 6}} className="d-flex flex-row align-items-center justify-content-end text-right">
          <Link to='/contact' className="callToAction">Contact Us</Link>
          {/* <div className="emailID"><a href="mailto:info@oneinfinity.in" className="callToAction">info@oneinfinity.in</a></div> */}
          <a href="https://www.linkedin.com/company/oneinfinity" target="_blank" rel="noopener noreferrer" className="d-flex justify-content-end"><LinkedInIcon className="socialicon lin"/></a>
        </Col>
      </Row>
      <Row className="footerBottom" gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
        <Col xs={{span:24}} md={{span:12}} lg={{span: 12}} className="copyright">
          <div className="text-left text-xs-center">© 2022 OneInfinity. All Rights Reserved.</div>
        </Col>
        <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
          <div className="text-right text-xs-center">
            <Menu mode="horizontal" items={resources} className="footer-btm-menu" />
          </div>
        </Col>
      </Row>
    </Footer>
  );
}
